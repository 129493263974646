import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeLottie from 'state/sanitizers/sanitizeLottie';

import { ThreeColumnAnimationModule } from 'types';

export default memoize((module: unknown): ThreeColumnAnimationModule => {
  return {
    type: get(module, '_type', 'threeColumnAnimationModule'),
    id: get(module, '_key', ''),
    header: get(module, 'header', ''),
    description: get(module, 'description', []),
    items: get(module, 'items', []).map((item: unknown) => {
      return {
        id: get(item, '_key', ''),
        title: get(item, 'title', ''),
        description: get(item, 'description', ''),
        image: sanitizeImage(get(item, 'image', '')),
        lottie: sanitizeLottie(get(item, 'lottie')),
      };
    }),
  };
});
