import memoize from 'lodash/memoize';
import get from 'lodash/get';
import theme from '@buoyhealth/common.buoy-theme';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeArticleLink from 'state/sanitizers/sanitizeArticleLink';
import sanitizeBlogPostLink from 'state/sanitizers/sanitizeBlogPostLink';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import {
  EmphasizeModuleColorScheme,
  EmphasizeModuleColorMap,
} from 'constants/ColorScheme';

import {
  ThreeColumnInfoModule,
  ThreeColumnInfoModuleContent,
  ThreeColumnInfoModuleContents,
  ThreeColumnModuleManualContent,
  ThreeColumnModuleArticleLink,
  ThreeColumnModuleBlogPostLink,
} from 'types';

const sanitizeContent = (content: any): ThreeColumnInfoModuleContent => {
  const type = get(content, '_type', '');

  if (type === 'manualContent') {
    return {
      type,
      id: get(content, '_key', ''),
      tabs: get(content, 'tabs', []),
      url: sanitizeInternalLink(get(content, 'url', '')),
      ariaLabel: get(content, 'ariaLabel', ''),
      image: sanitizeImage(get(content, 'image', {})),
      title: get(content, 'title', ''),
      description: get(content, 'description', ''),
      button: {
        label: get(content, 'buttonLabel', ''),
        ariaLabel: get(content, 'buttonAriaLabel', ''),
        url: sanitizeInternalLink(get(content, 'buttonUrl', '')),
      },
      content: content.content || [],
    } as ThreeColumnModuleManualContent;
  }

  if (type === 'article') {
    const articleRef = get(content, 'articleRef');

    if (!articleRef) {
      return null;
    }

    return {
      type,
      id: get(content, '_key', ''),
      tabs: get(content, 'tabs', []),
      article: sanitizeArticleLink(articleRef),
    } as ThreeColumnModuleArticleLink;
  }

  if (type === 'blogPost') {
    const blogPostRef = get(content, 'blogPostRef');

    if (!blogPostRef) {
      return null;
    }

    return {
      type,
      id: get(content, '_key', ''),
      tabs: get(content, 'tabs', []),
      blogPost: sanitizeBlogPostLink(blogPostRef),
      description: get(content, 'description', ''),
      image: sanitizeImage(get(content, 'image', '')),
      imageBackgroundColor: get(content, 'imageBackgroundColor', '#ffffff'),
    } as ThreeColumnModuleBlogPostLink;
  }

  return null;
};

export default memoize((module: unknown): ThreeColumnInfoModule => {
  let sanitizedTabs: string[] = [];

  const contents = get(module, 'contents', []).reduce(
    (sanitizedContents: ThreeColumnInfoModuleContents, content: unknown) => {
      const sanitizedContent: ThreeColumnInfoModuleContent =
        sanitizeContent(content);

      const tabs = sanitizedContent ? sanitizedContent.tabs : [];

      if (!tabs.length) {
        if (sanitizedContents['default']) {
          sanitizedContents['default'] = sanitizedContents['default'].concat([
            sanitizedContent,
          ]);
        } else {
          sanitizedContents['default'] = [sanitizedContent];
        }
      } else {
        tabs.forEach((tab: string) => {
          if (sanitizedContents[tab]) {
            sanitizedContents[tab] = sanitizedContents[tab].concat([
              sanitizedContent,
            ]);
          } else {
            sanitizedTabs = sanitizedTabs.concat([tab]);
            sanitizedContents[tab] = [sanitizedContent];
          }
        });
      }

      return sanitizedContents;
    },
    {}
  );

  const color = EmphasizeModuleColorMap[get(module, 'numberColor')];

  return {
    type: get(module, '_type', 'threeColumnInfo'),
    id: get(module, '_key', ''),
    anchor: get(module, 'anchor', ''),
    moduleOptions: sanitizeModuleOptions(module),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    button: {
      label: get(module, 'buttonLabel', ''),
      url: sanitizeInternalLink(get(module, 'buttonUrl', '')),
      variant: get(module, 'buttonVariant', 'primary'),
    },
    numberOptions: {
      displayNumber: get(module, 'displayNumber', false),
      bgColor: get(module, 'numberBgColor', theme.palette.common.white),
      color:
        get(EmphasizeModuleColorScheme, `${color}.background`) ||
        theme.palette.text.primary,
    },
    contentAlign: get(module, 'contentAlign', 'left'),
    isTwoColumnOnMobile: get(module, 'displayInTwoColumnsOnMobileView', false),
    maxItemsPerPage: parseInt(get(module, 'maxItemsPerPage', '9')),
    tabs: sanitizedTabs,
    contents,
  };
});
