import memoize from 'lodash/memoize';
import get from 'lodash/get';

import toCamelCase from 'utils/toCamelCase';

import { GlobalSettingsReducer } from 'state/reducers/globalSettings';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeInputField from 'state/sanitizers/sanitizeInputField';
import sanitizeSurveyPages from 'state/sanitizers/sanitizeSurveyPages';

import { DefaultSeoSettings } from 'constants/Default';
import { GeneralModuleColorMap } from 'constants/ColorScheme';

import {
  ExternalLink,
  FooterMenu,
  FooterImage,
  NavList,
  NavListItemChild,
  ErrorPage,
} from 'types';
import { TrendingArticle } from 'styled/components/Navigation/types';

const DefaultResearchFullPageViewDescriptionPortableText = [
  {
    _type: 'block',
    children: [
      {
        _type: 'span',
        marks: [],
        text: 'At Buoy Health, we want to deliver the best content to help address your health concerns.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _type: 'block',
    children: [
      {
        _type: 'span',
        marks: [],
        text: 'Help us understand how to improve on our content to provide you with the best resources and solutions!',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
];

export default memoize((globalSettings: unknown): GlobalSettingsReducer => {
  const navigation: NavList = (
    get(globalSettings, 'sideNavigation', []) as Array<any>
  ).reduce((nav: NavList, listItem: unknown, i: number): NavList => {
    const _id: string = get(listItem, '_key', '');
    const label: string = get(listItem, 'label', '');
    const labelFormat: string = get(listItem, 'labelFormat', '');
    const parent: string = get(listItem, 'parent', '');
    const slug: string = get(listItem, 'slug', '');
    const trendingArticles: TrendingArticle[] = get(
      listItem,
      'trendingArticles',
      [],
    );

    const id: string = toCamelCase(label);
    const parentId: string = toCamelCase(parent);

    const sanitizedListItem: NavListItemChild = {
      _id,
      label,
      labelFormat,
      parent,
      slug,
      trendingArticles,
    };

    if (parent) {
      if (!nav[parentId]?.children) {
        nav[parentId] = {
          ...nav[parentId],
          _id,
          children: [sanitizedListItem],
          label: parent,
          labelFormat,
        };
      } else {
        nav[parentId].children = nav[parentId].children.concat([
          sanitizedListItem,
        ]);
      }
    }

    nav[id] = nav[id]
      ? {
          ...sanitizedListItem,
          children: nav[id].children,
          order: i,
        }
      : {
          ...sanitizedListItem,
          children: [],
          order: i,
        };

    return nav;
  }, {});

  const footerMenu: FooterMenu[] = get(globalSettings, 'footerMenu', []).map(
    (menu: unknown) => {
      const menuList = get(menu, 'menuList', []).map((list: unknown) => {
        return {
          id: get(list, '_key', ''),
          label: get(list, 'label', ''),
          slug: get(list, 'slug', ''),
        };
      });

      return {
        title: get(menu, 'menuTitle', ''),
        list: menuList,
      };
    },
  );
  const footerSocial: ExternalLink[] = get(
    globalSettings,
    'footerSocial',
    [],
  ).map((social: unknown) => {
    return {
      id: get(social, '_key', ''),
      label: get(social, 'label', ''),
      url: get(social, 'link', ''),
    };
  });

  const errorPage: ErrorPage = {
    title: get(globalSettings, 'errorPageTitle', 'Page not found'),
    description: get(globalSettings, 'errorPageDescription', ''),
    link: {
      label: get(globalSettings, 'errorPageLinkLabel', ''),
      url: get(globalSettings, 'errorPageLinkUrl', ''),
    },
    image: sanitizeImage(get(globalSettings, 'errorPageImage')),
  };

  const footerImage: FooterImage = {
    image: sanitizeImage(get(globalSettings, 'footer.footerImage.image')),
    alternateImage: sanitizeImage(
      get(globalSettings, 'footer.footerImage.alternateImage'),
    ),
    text: get(globalSettings, 'footerImageText', ''),
  };

  const surveyDetails = JSON.parse(
    get(globalSettings, 'smGetSurveyDetailsResponseJSON'),
  );

  return {
    navigation,
    styleGuideLink: get(globalSettings, 'styleGuideLink', ''),
    notificationBar: {
      isActive: get(globalSettings, 'displayNotificationBar', false),
      text: get(globalSettings, 'notificationBarText', []),
    },
    footer: {
      menu: footerMenu,
      social: footerSocial,
      termsSlug: get(globalSettings, 'termsSlug', ''),
      privacySlug: get(globalSettings, 'privacySlug', ''),
      cookiesSlug: get(globalSettings, 'cookiesSlug', ''),
      footerImage,
    },
    popUp: {
      isActive: get(globalSettings, 'displayPopUp', false),
      text: get(globalSettings, 'popUpText', []),
      continueButtonLabel: get(globalSettings, 'popUpContinueButtonLabel', ''),
      secondaryButtonLabel: get(
        globalSettings,
        'popUpSecondaryButtonLabel',
        '',
      ),
      secondaryButtonLink: sanitizeInternalLink(
        get(globalSettings, 'popUpSecondaryButtonLink', ''),
      ),
    },
    verifiedByExpertsPopUp: {
      text: get(globalSettings, 'verifiedByExpertsPopUpText', []),
      subheading: get(globalSettings, 'verifiedByExpertsPopUpSubheading', ''),
      heading: get(globalSettings, 'verifiedByExpertsPopUpHeading', ''),
      headingImage: sanitizeImage(
        get(globalSettings, 'verifiedByExpertsPopUpHeadingImage'),
      ),
      headingBackgroundColor: get(
        globalSettings,
        'verifiedByExpertsPopUpHeadingBackgroundColor',
        '#ecf2fe',
      ),
    },
    errorPage,
    articleShareUserStory: {
      inputFields: get(globalSettings, 'shareUserStoryForm', []).map(
        (form: unknown) => sanitizeInputField(form),
      ),
      description: get(globalSettings, 'shareUserStoryFormDescription', ''),
      disclaimer: get(globalSettings, 'shareUserStoryFormDisclaimer', ''),
    },
    generalInquiry: {
      inputFields: get(globalSettings, 'generalInquiryForm', []).map(
        (form: unknown) => sanitizeInputField(form),
      ),
      sectionTitle: get(globalSettings, 'generalInquirySectionTitle', ''),
      sectionDescription: get(
        globalSettings,
        'generalInquirySectionDescription',
        [],
      ),
      formDescription: get(globalSettings, 'generalInquiryFormDescription', ''),
    },
    seo: {
      title: get(globalSettings, 'metaTitle', DefaultSeoSettings.title),
      description: get(
        globalSettings,
        'metaDescription',
        DefaultSeoSettings.description,
      ),
      image: sanitizeImage(get(globalSettings, 'metaImage')),
      structuredData: null,
      canonicalUrl: get(globalSettings, 'canonicalUrl', ''),
    },
    instagramToken: get(globalSettings, 'instagramToken', ''),
    research: {
      surveyPages: sanitizeSurveyPages(surveyDetails),
      surveyUrl: get(globalSettings, 'smSurveyUrl', ''),
      surveyQuestionCount: get(surveyDetails, 'question_count'),
      surveyCustomVariables: get(surveyDetails, 'custom_variables', {}),
      moduleTitle: get(
        globalSettings,
        'researchModuleTitle',
        'Quick question for you',
      ),
      moduleDescription: get(
        globalSettings,
        'researchModuleDescription',
        'Feedback like yours help us provide the most useful resources possible.',
      ),
      moduleImage: sanitizeImage(get(globalSettings, 'researchModuleImage')),
      moduleColor:
        GeneralModuleColorMap[
          get(globalSettings, 'researchModuleColor', '#f8edeb')
        ] || 'ROSE',
      moduleQuestionCount: get(
        globalSettings,
        'researchModuleQuestionCount',
        1,
      ),
      fullPageViewTitle: get(
        globalSettings,
        'researchFullPageViewTitle',
        'Help us bring better content to you',
      ),
      fullPageViewDescription: get(
        globalSettings,
        'fullPageViewDescription',
        DefaultResearchFullPageViewDescriptionPortableText,
      ),
    },
  };
});
