import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { AccordionListModule, AccordionListItem } from 'types';

export default memoize((module: unknown): AccordionListModule => {
  const items: AccordionListItem[] = get(module, 'listItems', []).map(
    (item: unknown) => {
      return {
        id: get(item, '_key', ''),
        button: {
          label: get(item, 'label', ''),
          description: get(item, 'description', ''),
        },
        content: get(item, 'content', []),
      };
    }
  );

  return {
    type: get(module, '_type', 'accordionList'),
    id: get(module, '_key', ''),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    listItems: items,
    moduleOptions: sanitizeModuleOptions(module),
    bottomDescription: get(module, 'bottomDescription', []),
    button: {
      label: get(module, 'buttonLabel', ''),
      url: sanitizeInternalLink(get(module, 'buttonUrl', '')),
    },
  };
});
