import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { NumberedListItem, NumberedListWithImageModule } from 'types';
import {
  SplitCtaColorMap,
  EmphasizeModuleColorMap,
} from 'constants/ColorScheme';

export default memoize((module: unknown): NumberedListWithImageModule => {
  const sanitizedContents: NumberedListItem[] = get(module, 'contents', []).map(
    (item: unknown) => {
      return {
        id: get(item, '_key', ''),
        title: get(item, 'title', ''),
        text: get(item, 'text', []),
        image: sanitizeImage(get(item, 'image', '')),
        desktopImageAlignment: get(item, 'desktopImageAlignment', null),
        displayDividerBetweenStatisticsListAndText: get(
          item,
          'displayDividerBetweenStatisticsListAndText',
          false
        ),
        statisticsList: get(item, 'statisticsList', []).map(
          (statistic: unknown) => ({
            highlightedText: get(statistic, 'highlightedText', ''),
            statisticText: get(statistic, 'statisticText', []),
            textColor: get(statistic, 'textColor', '#196cff'),
            source: get(statistic, 'source', []),
          })
        ),
      };
    }
  );

  return {
    type: get(module, '_type', 'numberedListWithImage'),
    id: get(module, '_key', ''),
    moduleOptions: sanitizeModuleOptions(module),
    numberOptions: {
      displayNumber: get(module, 'displayNumber', false),
      bgColor:
        SplitCtaColorMap[get(module, 'numberBgColor', '#f7ded7')] || 'ROSE',
      color: EmphasizeModuleColorMap[get(module, 'numberColor')] || null,
    },
    moduleTitle: get(module, 'moduleTitle', ''),
    moduleDescription: get(module, 'moduleDescription', []),
    topImage: sanitizeImage(get(module, 'topImage', '')),
    contents: sanitizedContents,
  };
});
