import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { Lottie } from 'types';

export default memoize((lottie: unknown): Lottie => {
  const id = get(lottie, '_id', '');

  if (!id) {
    return null;
  }

  return {
    id: get(lottie, '_id', ''),
    url: get(lottie, 'url', ''),
    alt: get(lottie, 'alt', ''),
  };
});
