import { ArticleContent, DxListModule } from 'types';

/** Extract common cause names from article modules */
export const getCommonCauses = (content: ArticleContent) => {
  const commonCausesModule = content.bodyModules.find(
    (module) => module?.type === 'dxList'
  ) as DxListModule;

  if (!commonCausesModule) {
    return [];
  }

  return commonCausesModule.items.map((cause) => ({
    name: cause.title,
    slug: cause.slug || '',
  }));
};
