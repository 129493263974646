import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { ListItemsAndLogosModule } from 'types';

export default memoize((module: unknown): ListItemsAndLogosModule => {
  return {
    type: get(module, '_type', 'listItemsAndLogosModule'),
    id: get(module, '_key', ''),
    header: get(module, 'header', ''),
    listItems: get(module, 'listItems', []).map((listItem: unknown) => {
      return {
        id: get(listItem, '_key', ''),
        text: get(listItem, 'text', ''),
        icon: sanitizeImage(get(listItem, 'icon', '')),
      };
    }),
    images: get(module, 'images', []).map((item: unknown) => {
      return sanitizeImage(get(item, 'image', ''));
    }),
  };
});
