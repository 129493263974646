import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';
import sanitizeContentTag from './sanitizeContentTag';

import { TextModule } from 'types';

import { FontSizes, Fonts } from 'styled/theme/typography';

export default memoize((module: any): TextModule => {
  return {
    type: get(module, '_type', 'textModule'),
    id: get(module, '_key', ''),
    addToSeoFaqJsonSchema: module.addToSeoFaqJsonSchema || false,
    anchor: get(module, 'anchor', ''),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    button: {
      label: get(module, 'buttonLabel', ''),
      url: sanitizeInternalLink(get(module, 'buttonUrl', '')),
      variant: get(module, 'buttonVariant', 'primary'),
    },
    title: get(module, 'contentTitle', ''),
    titleFontSize: get(module, 'contentTitleFontSize', FontSizes.xl),
    contents: get(module, 'contents', []),
    contentFont: get(module, 'contentFont', Fonts.SPECTRAL),
    contentFontSize: get(module, 'contentFontSize', FontSizes.sm),
    contentTitleAlignment: get(module, 'contentTitleAlignment', 'left'),
    contentTag: sanitizeContentTag(module.contentTag || {}),
    contentAlignment: get(module, 'contentAlignment', 'left'),
    contentIsTwoColumnOnDesktop: get(module, 'twoColumnContent', false),
    separateTitleAndContentOnDesktop: get(
      module,
      'separateTitleAndContent',
      false,
    ),
    contentIsFullWidth: get(module, 'renderContentFullWidth', false),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
