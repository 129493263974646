import memoize from 'lodash/memoize';
import get from 'lodash/get';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { BannerModule } from 'types';

export default memoize((module: unknown): BannerModule => {
  return {
    type: get(module, '_type', 'banner'),
    id: get(module, '_key', ''),
    title: get(module, 'title', ''),
    category: get(module, 'category', ''),
    date: get(module, 'date', ''),
    buttonLabel: get(module, 'buttonLabel', ''),
    buttonLink: get(module, 'buttonLink', ''),
    image: sanitizeImage(get(module, 'image')),
  };
});
