import memoize from 'lodash/memoize';
import get from 'lodash/get';
import theme from '@buoyhealth/common.buoy-theme';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { ThumbnailCardsModule } from 'types';

export default memoize((module: unknown): ThumbnailCardsModule => {
  return {
    type: get(module, '_type', 'thumbnailCards'),
    id: get(module, '_key', ''),
    cards: get(module, 'cards', []).map((item: unknown) => {
      return {
        id: get(item, '_key', ''),
        title: get(item, 'title', ''),
        description: get(item, 'description', []),
        url: get(item, 'url', ''),
        image: sanitizeImage(get(item, 'image', '')),
        backgroundColor: get(
          item,
          'backgroundColor',
          theme.palette.primary.main
        ),
      };
    }),
  };
});
