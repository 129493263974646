import BuoyClient from 'lib/BuoyClient';
import { SubmitOnboardingFormAction } from 'state/actions/types/formActionsTypes';
import { FormValues, WebToLeadSubmissionMethod } from 'types';

export const submitOnboardingForm = (
  form: FormValues,
  method: WebToLeadSubmissionMethod
): SubmitOnboardingFormAction => {
  if (method === 'salesforce') {
    return {
      type: 'SUBMIT_ONBOARDING_FORM',
      payload: BuoyClient.postWebToLeadForm(form),
    };
  }
  return {
    type: 'SUBMIT_ONBOARDING_FORM',
    payload: BuoyClient.postWebToLeadFormViaChiliPiper(form),
  };
};
