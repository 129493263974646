import { NextPage } from 'next';
import { reduxWrapper } from 'store';

import get from 'lodash/get';

import { fetchGenericPage } from 'state/actions/genericPageActions';
import { CacheControlHeaders } from 'constants/Global';

import GenericPageView from 'views/GenericPageView';

import { RouteMap } from 'constants/Routes';

import { GenericPage, ErrorPage as IErrorPage } from 'types';

interface InitialProps {
  frontPage: GenericPage | null;
  errorPage: IErrorPage;
}

type Props = InitialProps;

const HomePage: NextPage<Props> = (props) => {
  return (
    <GenericPageView
      page={props.frontPage}
      errorPage={props.errorPage}
      disableAds
    />
  );
};

export const getServerSideProps = reduxWrapper.getServerSideProps(
  ({ store, query, res }) => {
    res.setHeader('Cache-Control', CacheControlHeaders.NO_CACHE);
    const preview = get(query, 'generic_page_preview', '') as string;
    const frontPageFromStore = store.getState().genericPage[RouteMap.HOME.path];

    if (!preview && frontPageFromStore) {
      return {
        props: {
          frontPage: frontPageFromStore,
        },
      };
    }

    const action = fetchGenericPage(RouteMap.HOME.path, preview);
    store.dispatch(action);

    return action.payload.then((frontPage) => {
      if (!frontPage) {
        if (res) {
          res.statusCode = 404;
        }
      }

      return {
        props: {
          frontPage: frontPage,
        },
      };
    });
  },
);

export default reduxWrapper.withRedux(HomePage);
