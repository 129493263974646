import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { InstagramFeedImageItem } from 'types';

export default memoize(
  (instagramFeed: unknown): InstagramFeedImageItem => ({
    id: get(instagramFeed, 'id', ''),
    src: get(instagramFeed, 'image', ''),
    link: get(instagramFeed, 'link', ''),
  })
);
