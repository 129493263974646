import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { CtaModule } from 'types';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

export default memoize((module: unknown): CtaModule => {
  return {
    type: get(module, '_type', 'cta'),
    id: get(module, '_key', ''),
    text: get(module, 'text', []),
    image: sanitizeImage(get(module, 'image')),
    desktopImageMaxWidth: get(module, 'desktopImageMaxWidth', null),
  };
});
