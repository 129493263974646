import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { TesterRecruitmentCtaModule } from 'types';

export default memoize((module: unknown): TesterRecruitmentCtaModule => {
  return {
    type: get(module, '_type', 'testerRecruitmentCta'),
    id: get(module, '_key', ''),
    mainCta: get(module, 'mainCta', ''),
    supportingCta: get(module, 'supportingCta', ''),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
