import { PortableTextBlock } from '@portabletext/types';
import memoize from 'lodash/memoize';
import { IQueryModule } from 'types';
import sanitizeSideNavOption from '../articleBodyModules/sanitizeSideNavOption';
import type { MiniInterviewType } from '@buoy-components/polaris/types/interviews';

export interface ISanityQueryModule {
  _type: 'queryModule';
  _key: string;
  title: string;
  queries: Array<{
    question: string;
    answer: PortableTextBlock;
    typeFormID?: string;
    miniInterview?: MiniInterviewType;
  }>;
  displaySearchBar: boolean;
}

export default memoize((module: ISanityQueryModule): IQueryModule => {
  return {
    type: module._type,
    id: module._key,
    title: module.title || '',
    queries: module.queries || [],
    displaySearchBar: module.displaySearchBar,
    sideNav: sanitizeSideNavOption(module),
  };
});
