import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled, { useTheme } from 'styled-components';
import { IInsuranceFormModule, Status, InputField, FormValues } from 'types';
import { Form } from 'styled';
import { trackStructuredEvent } from 'analytics';
import { stateCodes } from 'lib/constants';
import TrustedFormScript from './TrustedForm';
import BuoyClient from 'lib/BuoyClient';
import Theme from 'styled/theme';

interface IInsuranceFormModuleProps {
  module: IInsuranceFormModule;
}

interface IInsuranceForm {
  name: string;
  email: string;
  phone: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  gender: string;
  household_size: string;
  preexisting_conditions: string;
  income: string;
  agreement: string;
}

const inputFields: InputField[] = [
  {
    label: 'Phone Number',
    title: 'phone_home',
    isRequired: true,
    type: 'tel',
    options: [],
  },
  {
    label: 'State',
    title: 'state',
    isRequired: true,
    type: 'text',
    options: stateCodes,
  },
];

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 5rem;
`;

const Inner = styled.div`
  max-width: 100%;

  ${Theme.mediaQueries.md} {
    max-width: 30rem;
  }
`;

const InsuranceFormModule = ({ module }: IInsuranceFormModuleProps) => {
  const { palette } = useTheme();
  const router = useRouter();
  const [status, setStatus] = useState(Status.IDLE);
  const [errorMsg, setErrorMsg] = useState('');

  const getCertId = (): string => {
    let certUrl = document
      .getElementById('xxTrustedFormCertUrl_0')
      ?.getAttribute('value');
    if (!certUrl) {
      return '';
    }

    const certChunks = certUrl.split('/');
    certUrl = certChunks[certChunks.length - 1];
    return certUrl;
  };

  const onSubmit = async (formValues: FormValues) => {
    setStatus(Status.PENDING);
    trackStructuredEvent({
      action: 'insurance-form-submit',
      category: 'insurance-form',
    });
    formValues['trusted_form_cert_id'] = getCertId();
    const response = await BuoyClient.postInsuranceForm(formValues);
    trackStructuredEvent({
      action: response.status,
      category: 'insurance-form',
      label: response.message,
    });
    if (response.status === 'insurance-form-success') {
      setStatus(Status.FULFILLED);
      router.push('/blog/insurance/insuranceplans');
    } else if (response.status === 'insurance-form-rejected') {
      setStatus(Status.REJECTED);
      setErrorMsg(
        "We're sorry, it looks like we can't find any plans for you right now."
      );
    } else {
      setStatus(Status.REJECTED);
      setErrorMsg("We're sorry, something went wrong. Please try again later.");
    }
  };

  return (
    <Container>
      <Inner>
        <Form
          className="InsuranceModule__form w100"
          color={palette.gray[60]}
          inputFields={inputFields}
          handleSubmit={onSubmit}
          formSubmitStatus={status}
          submitButtonLabel="See Plans"
          termsAgreement={true}
          title="Insurance Form"
          description="By submitting this form, I am giving Buoy Health my express written consent to have a vetted agent contact me about health insurance by email, telephone, and/or text messaging using automated technology at the end and telephone number(s) provided above, including wireless."
          errorMsg={errorMsg}
        />
        {module.trustedFormEnabled && <TrustedFormScript />}
      </Inner>
    </Container>
  );
};

export default InsuranceFormModule;
