import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { JobPostingsModule } from 'types';

export default memoize((module: unknown): JobPostingsModule => {
  return {
    type: get(module, '_type', 'jobPostings'),
    id: get(module, '_key', ''),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    moduleOptions: sanitizeModuleOptions(module),
    bottomDescription: get(module, 'bottomDescription', []),
    button: {
      label: get(module, 'buttonLabel', ''),
      url: sanitizeInternalLink(get(module, 'buttonUrl', '')),
    },
  };
});
