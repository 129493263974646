import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeAccordionListModule from 'state/sanitizers/modules/sanitizeAccordionListModule';
import sanitizeEmphasisLinkCardModule from 'state/sanitizers/modules/sanitizeEmphasisLinkCardModule';
import sanitizeNumberedListWithImageModule from 'state/sanitizers/modules/sanitizeNumberedListWithImageModule';
import sanitizeSingleBlockCtaModule from 'state/sanitizers/modules/sanitizeSingleBlockCtaModule';
import sanitizeTextModule from 'state/sanitizers/modules/sanitizeTextModule';
import sanitizeThreeColumnInfoModule from 'state/sanitizers/modules/sanitizeThreeColumnInfoModule';

import { EmphasizeModuleColorMap } from 'constants/ColorScheme';

import { PageHero } from 'types';

export default memoize(
  (page: unknown): PageHero => ({
    variant: get(page, 'heroVariant', 'general-lg'),
    title: get(page, 'heroTitle', ''),
    description: get(page, 'heroDescription', ''),
    color:
      EmphasizeModuleColorMap[get(page, 'heroColor', '#ffffff')] || 'WHITE',
    desktopImage: sanitizeImage(get(page, 'heroDesktopImage')),
    mobileImage: sanitizeImage(get(page, 'heroMobileImage')),
    desktopImageWidth: get(page, 'desktopHeroImageWidth', 0),
    mobileImageWidth: get(page, 'modileHeroImageWidth', 0),
    modules: get(page, 'heroModuleRepeater', []).map((module: any) => {
      const type = get(module, '_type');

      if (!type) {
        return null;
      }

      switch (type) {
        case 'accordionList':
          return sanitizeAccordionListModule(module);
        case 'emphasisLinkCard':
          return sanitizeEmphasisLinkCardModule(module);
        case 'numberedListWithImage':
          return sanitizeNumberedListWithImageModule(module);
        case 'singleBlockCta':
          return sanitizeSingleBlockCtaModule(module);
        case 'textModule':
          return sanitizeTextModule(module);
        case 'threeColumnInfo':
          return sanitizeThreeColumnInfoModule(module);
        default:
          return null;
      }
    }),
    buttonLink: sanitizeInternalLink(get(page, 'heroButtonLink', '')),
    buttonLabel: get(page, 'heroButtonLabel', ''),
    buttonVariant: get(page, 'heroButtonVariant', ''),
    gtmTrackerEventName: get(page, 'gtmTrackerEventName', ''),
  }),
);
