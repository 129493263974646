import React, { FC } from 'react';
import Head from 'next/head';
import { toPlainText } from '@portabletext/react';
import extractMainSymptomsFromDxArticleBody from 'utils/extractMainSymptomsFromDxArticleBody';
import {
  Article,
  ArticleBodyModule,
  ArticleText,
  ArticleType,
  IQueryModuleQuery,
} from 'types';
import dashSeparatedStringToTitleCase from 'utils/dashSeparatedStringToTitleCase';
import {
  getAnswer,
  getQuestion,
} from 'utils/extractQuestionAndAnswerFromTextModule';

interface Props {
  article: Article;
}

const ArticleStructuredDataSchema: FC<Props> = ({ article }) => {
  const { meta, seo, slug, articleType, content, conditionName } = article;
  const { author, reviewer, publishedDate, modifiedDate } = meta;
  const { bodyModules } = content;

  const getArticleTextModulesWithSeoFaqEnabled = (
    modules: ArticleBodyModule[]
  ): ArticleText[] | [] => {
    if (!modules) return [];

    const articleTextArray: ArticleText[] = modules.reduce(
      (textModules: ArticleText[], module: ArticleBodyModule) => {
        // only articleText modules with addToSeoFaqJsonSchema set to true should be returned
        if (module?.type === 'articleText' && module?.addToSeoFaqJsonSchema) {
          textModules.push(module);
        }

        return textModules;
      },
      []
    );

    return articleTextArray;
  };

  const getQueryModuleStaticAnswersForSeoFaq = (
    modules: ArticleBodyModule[]
  ) => {
    if (!modules) return [];

    const staticAnswerArray: IQueryModuleQuery[] = modules.reduce(
      (
        queriesWithStaticAnswers: IQueryModuleQuery[],
        module: ArticleBodyModule
      ) => {
        // only queries with static answers should be returned
        if (module?.type === 'queryModule' && module?.queries.length) {
          module.queries.forEach((query) => {
            if (query.answer) {
              queriesWithStaticAnswers.push(query);
            }
          });
        }

        return queriesWithStaticAnswers;
      },
      []
    );

    return staticAnswerArray;
  };

  const baseSchemaJson = {
    '@context': 'https://schema.org',
    publisher: {
      '@type': 'Organization',
      name: 'Buoy Health, Inc.',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.buoyhealth.com/static/images/app_icons/ios-icon-192x192.png',
      },
    },
    '@type': 'MedicalWebPage',
    headline: seo.title,
    description: seo.description,
    author: author
      ? {
          '@type': 'Person',
          name: author.fullName,
          sameas: `https://www.buoyhealth.com/writers${author.slug}`,
        }
      : undefined,
    lastReviewed: modifiedDate,
    url: `https://www.buoyhealth.com/learn${slug}`,
    datePublished: publishedDate,
    reviewedBy: reviewer
      ? {
          '@type': 'Person',
          name: reviewer.fullName,
          sameas: `https://www.buoyhealth.com/writers${reviewer.slug}`,
        }
      : undefined,
    image: [seo.image.src],
  };

  const mainSymptoms =
    articleType === ArticleType.Dx && !!content.bodyModules.length
      ? extractMainSymptomsFromDxArticleBody(content.bodyModules)
      : [];

  const dxArticleSchemaJson = {
    ...baseSchemaJson,
    about: {
      '@type': 'MedicalCondition',
      name: conditionName || dashSeparatedStringToTitleCase(slug.substring(1)),
      signOrSymptom: mainSymptoms.length
        ? mainSymptoms.map((symptom) => ({
            '@type': 'MedicalSymptom',
            name: symptom,
          }))
        : undefined,
    },
  };

  const faqPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      ...getArticleTextModulesWithSeoFaqEnabled(bodyModules)?.map(
        (articleTextModule) => ({
          '@type': 'Question',
          name: getQuestion(articleTextModule.text),
          acceptedAnswer: {
            '@type': 'Answer',
            text: getAnswer(articleTextModule.text),
          },
        })
      ),
      ...getQueryModuleStaticAnswersForSeoFaq(bodyModules)?.map(
        (queryModule) => ({
          '@type': 'Question',
          name: queryModule.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: queryModule.answer ? toPlainText(queryModule.answer) : '',
          },
        })
      ),
    ],
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            articleType === ArticleType.Dx
              ? dxArticleSchemaJson
              : baseSchemaJson
          ),
        }}
      />
      {/* if addToSeoFaqJsonSchema is true, Q&A content will be added to the FAQPageSchema  */}
      {faqPageSchema.mainEntity.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faqPageSchema),
          }}
        />
      )}
    </Head>
  );
};

export default ArticleStructuredDataSchema;
