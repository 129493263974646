import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { VideoAndListItemsModule } from 'types';

export default memoize((module: unknown): VideoAndListItemsModule => {
  return {
    type: get(module, '_type', 'videoAndListItemsModule'),
    id: get(module, '_key', ''),
    header: get(module, 'header', ''),
    video: {
      vimeoId: get(module, 'video.vimeoId', ''),
      youtubeId: get(module, 'video.youtubeId', ''),
    },
    listItems: get(module, 'listItems', []).map((listItem: unknown) => {
      return {
        id: get(listItem, '_key', ''),
        text: get(listItem, 'text', ''),
      };
    }),
  };
});
