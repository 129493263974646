import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BetaChip } from '@buoyhealth/common.buoy-chip';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { v4 as uuid } from 'uuid';
import { withImpressionTracking } from 'analytics';

import { Base } from '../../base';
import BazaarClient from 'lib/BazaarClient';
import BuoyGPTSearch from 'styled/components/QueryModule/components/BuoyGPTSearch';
import { Cases, formatTitleCasing } from 'utils/formatTitleCasing';
import ErrorBoundary from 'components/ErrorBoundary';
import { getCommonCauses } from 'styled/components/QueryModule/utils/getCommonCauses';
import Interview from './Interview';

import { trackModuleImpression } from './analytics';
import useAffiliateProducts from './hooks/useAffiliateProducts';
import useBreakpointIsMobile from 'hooks/useBreakpointIsMobile';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePage from 'hooks/usePage';
import usePageSlug from 'hooks/usePageSlug';
import useLanguage from 'hooks/useLanguage';
import { View } from 'styled/components/Polaris/constants';
import { ArticlePortableText } from '../../portableText';
import { MobileDivider } from '../../QueryModule/styles';

import {
  MINIMUM_PRODUCTS_COUNT,
  POLARIS_EAGER_LOAD_FLAG,
  SX_TG_ENABLED_FLOWS,
  TREATMENT_GUIDANCE_EMBEDDED_INTERVIEW_FLAG,
  TXOC_FLAG,
} from './constants';

import type { IEmbeddedInterview, IInterview } from 'types';
import type {
  IInterviewConfig,
  MiniInterviewType,
} from '@buoy-components/polaris/types';

import {
  buildAllInterviewConfigs,
  shouldShowPolarisPopup,
  shouldShowEmbeddedInterview,
  buildPolarisConfiguration,
  sortInterviews,
} from './utils';
import Polaris from 'styled/components/Polaris';
import { SYMPTOM_CATEGORY, DIAGNOSIS_CATEGORY } from '../../constants';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';
import { trackPopupOpened } from 'analytics/polaris';

interface IEmbeddedInterviewProps {
  module: IEmbeddedInterview;
  /** Whether the buoy gpt search "or, ask a different question" bar should show */
  showSearchBar?: boolean;
}

/** This module displays embedded interviews. After the user answers the first
 *  question, the rest of the interview will continue in a dialog (mobile) or
 *  side drawer (desktop) using the "Polaris" styles.
 *  Note: this is more of a quick iteration to see how users respond to the dialog
 *  and will probably have to be re-jeuzged once the real Polaris architecture
 *  is in place.
 * */
const EmbeddedInterview = ({
  module,
  showSearchBar,
}: IEmbeddedInterviewProps) => {
  const isMobile = useBreakpointIsMobile();
  const featureFlags = useFeatureFlags();
  const Language = useLanguage();
  const moduleId = useMemo(() => uuid(), []);
  const { article, quiz, blogPost } = usePage();
  const { data } = useAffiliateProducts({
    blogPost,
  });
  const { blogAffiliateProducts } = data || {};
  const { content } = article || {};
  const slug = usePageSlug();
  const containerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [isInViewport, setIsInViewport] = useState(
    featureFlags?.[POLARIS_EAGER_LOAD_FLAG],
  );
  const {
    activeInterview,
    isPolarisDisabled,
    isPopupOpen,
    setActiveInterview,
    setIsPolarisDisabled,
    setIsPopupOpen,
  } = useContext(PolarisPopUpContext);

  const shouldFetchPredictors = useMemo(
    () =>
      module.interviews.some((interview: IInterview) =>
        ['dxoc', 'symptoms', 'emergency', 'sxTreatmentGuidance'].includes(
          interview.interview,
        ),
      ),
    [module.interviews],
  );

  const { data: predictors = [] } = useQuery(
    ['predictors', slug],
    async () => {
      if (slug) {
        const isDxocInterview = module.interviews.some(
          (interview: IInterview) => interview.interview === 'dxoc',
        );
        const category = isDxocInterview
          ? DIAGNOSIS_CATEGORY
          : SYMPTOM_CATEGORY;
        const predictors = await BazaarClient.getPredictorsFromSlug(
          slug,
          category,
        );

        if (predictors?.length) {
          return predictors.map((p) => p.predictor_id);
        }
      }
    },
    { enabled: !!slug && shouldFetchPredictors, refetchOnWindowFocus: false },
  );

  const TrackingContainer: React.FC<{ children: React.ReactNode }> = useMemo(
    () =>
      withImpressionTracking(Base, () => {
        trackModuleImpression(moduleId);
        setIsInViewport(true);
      }),
    [moduleId],
  );

  const handleExit = useCallback(() => {
    setIsPopupOpen(false);
  }, [setIsPopupOpen]);

  const handleQuestionSubmit = useCallback((interview: MiniInterviewType) => {
    setActiveInterview(interview);
    setIsPopupOpen(true);
    trackPopupOpened('embedded-query-module');
  }, []);

  const {
    interviews: moduleInterviews,
    title: moduleTitle,
    description,
  } = module;

  const interviews = useMemo(
    () => sortInterviews(moduleInterviews, article?.articleType),
    [article?.articleType, moduleInterviews],
  );

  const commonCauses = useMemo(
    () => (content ? getCommonCauses(content) : []),
    [content],
  );

  const conditionName = formatTitleCasing({
    title:
      article?.conditionName || article?.title || quiz?.conditionName || '',
    casing: Cases.LowerCase,
  });

  /* NOTE: Specific to SxTG due to initial rollout restrictions.
   * - Only allow verfied common causes for SxTG
   * - Leave non SxTG common causes as is
   * */
  const filteredCommonCauses = commonCauses.filter(
    (cause) =>
      slug && SX_TG_ENABLED_FLOWS?.[slug]?.enabledDxs.includes(cause.name),
  );

  // Interview configuration
  // - includes props and additional context for interview to run
  const configs: Record<string, IInterviewConfig[]> = useMemo(
    () =>
      buildAllInterviewConfigs({
        commonCauses,
        filteredCommonCauses: filteredCommonCauses,
        predictors,
        conditionName,
        handleQuestionSubmit,
        interviews,
        slug: slug || router.asPath?.split('?')[0],
      }),
    [
      commonCauses,
      filteredCommonCauses,
      predictors,
      conditionName,
      handleQuestionSubmit,
      interviews,
      slug,
      router.asPath,
    ],
  );

  /* Treatment Guidance is valid if:
   * - At least 3 affiliate products exist for the page
   * - The interview has questions
   */
  const isValidTreatmentGuidanceInterview = useCallback(
    (interview: IInterview) =>
      interview.interview === 'treatmentGuidance' &&
      (blogAffiliateProducts?.length || 0) >= MINIMUM_PRODUCTS_COUNT &&
      !!interview.questions?.length,
    [blogAffiliateProducts],
  );

  /* Sx Treatment Guidance is valid if:
   * - Interview is of type sxTreatmentGuidance
   * - SxTG is enabled for the page
   */
  const isValidSxTreatmentGuidanceInterview = useCallback(
    (interview: IInterview) =>
      interview.interview === 'sxTreatmentGuidance' &&
      Object.keys(SX_TG_ENABLED_FLOWS).includes(slug || ''),
    [slug],
  );

  const treatmentGuidance = useMemo(
    () =>
      interviews.find((interview: IInterview) =>
        isValidTreatmentGuidanceInterview(interview),
      ),
    [interviews, isValidTreatmentGuidanceInterview],
  );

  const sxTreatmentGuidance = useMemo(
    () =>
      interviews.find((interview: IInterview) =>
        isValidSxTreatmentGuidanceInterview(interview),
      ),
    [interviews, isValidSxTreatmentGuidanceInterview],
  );

  const txoc = useMemo(
    () =>
      interviews.find(
        (interview: IInterview) =>
          interview.interview === 'txoc' && featureFlags?.[TXOC_FLAG],
      ),
    [interviews, featureFlags],
  );

  /* Embed Treatment Guidance interview when:
   * - The feature flag is enabled
   * - It is a valid treatment guidance interview
   */
  const isEmbeddedTreatmentGuidanceInterview = useCallback(
    (interview: IInterview) =>
      featureFlags?.[TREATMENT_GUIDANCE_EMBEDDED_INTERVIEW_FLAG] &&
      isValidTreatmentGuidanceInterview(interview),
    [featureFlags, isValidTreatmentGuidanceInterview],
  );

  const title =
    moduleTitle ||
    treatmentGuidance?.question ||
    Language.t('EmbeddedInterview.title');

  const showEmbeddedModule = useCallback(
    (interview: IInterview) => {
      return shouldShowEmbeddedInterview({
        interviewType: interview.interview,
        isValidSxTg: !!sxTreatmentGuidance,
        isValidTxoc: !!txoc,
        predictors,
        shouldFetchPredictors,
      });
    },
    [predictors, shouldFetchPredictors, sxTreatmentGuidance],
  );

  const showPolarisPopup = useMemo(() => {
    return shouldShowPolarisPopup({
      enabled: featureFlags?.['growth-public-site-polaris'],
      validTreatmentGuidance: !!treatmentGuidance,
      shouldFetchPredictors,
      predictors,
    });
  }, [featureFlags, treatmentGuidance, predictors, shouldFetchPredictors]);

  const embeddedInterviews = interviews.filter(
    (interview) =>
      (interview.interview !== 'treatmentGuidance' ||
        isEmbeddedTreatmentGuidanceInterview(interview)) &&
      showEmbeddedModule(interview),
  );

  const polarisConfiguration = useMemo(
    () =>
      buildPolarisConfiguration({
        configs,
        visibleInterviews: embeddedInterviews,
        slug,
        featureFlags,
        conditionName,
        articleType: article?.articleType || undefined,
        language: article?.language,
      }) || [],
    [
      article?.articleType,
      article?.language,
      conditionName,
      configs,
      embeddedInterviews,
      featureFlags,
      slug,
    ],
  );

  useEffect(() => {
    if (isPolarisDisabled && embeddedInterviews.length) {
      setIsPolarisDisabled(false);
    } else if (!isPolarisDisabled && !embeddedInterviews.length) {
      setIsPolarisDisabled(true);
    }
  }, [embeddedInterviews.length, isPolarisDisabled, setIsPolarisDisabled]);

  if (!embeddedInterviews.length) {
    return null;
  }

  return (
    <ErrorBoundary fallback={null}>
      {!!embeddedInterviews.length && (
        <div
          className="w100 md:px0 mb-16 mt-12"
          data-testid="embedded-interview"
        >
          <MobileDivider />
          <TrackingContainer>
            <Base ref={containerRef} margin={['2rem 0', '2rem 0', '2rem 0', 0]}>
              <BetaChip
                label={`⚡️ ${Language.t('EmbeddedInterview.chip')}`}
                sx={{ textTransform: 'uppercase' }}
                withBuoyTheme
              />
              <ArticlePortableText>
                {isMobile ? (
                  <h3 className="!mb-2 !mt-1.5">{title}</h3>
                ) : (
                  <h2 className="!mb-4 !mt-2">{title}</h2>
                )}
                <p className="mb-6 md:mb-8">
                  {description || Language.t('EmbeddedInterview.description')}
                </p>
              </ArticlePortableText>
              <Grid container spacing={2} direction="column">
                {isInViewport &&
                  embeddedInterviews.map((interview, i) => {
                    return (
                      <Grid
                        className="w-full"
                        data-testid={`embedded-interview-${interview.interview}-${i}`}
                        item
                        key={interview._key}
                      >
                        <Interview
                          handleQuestionSubmit={handleQuestionSubmit}
                          interview={interview}
                          interviewSettings={polarisConfiguration}
                          isActive={
                            activeInterview !== undefined
                              ? activeInterview === interview.interview
                              : i === 0
                          }
                          isPopupOpen={showPolarisPopup && isPopupOpen}
                          onExit={handleExit}
                          onPopupOpen={(view) => {
                            setIsPopupOpen(view === View.FullScreen);
                          }}
                        />
                      </Grid>
                    );
                  })}
                {showSearchBar && (
                  <Grid item>
                    <BuoyGPTSearch moduleId={moduleId} />
                  </Grid>
                )}
              </Grid>
            </Base>
          </TrackingContainer>
          <MobileDivider />
        </div>
      )}
      {showPolarisPopup && (
        <Polaris
          interviewSettings={polarisConfiguration}
          handleExitClick={handleExit}
        />
      )}
    </ErrorBoundary>
  );
};

export default EmbeddedInterview;
