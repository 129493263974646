import styled from 'styled-components';
import { Base, Theme, LinkOrButton } from 'styled';

type PageHeroProps = {
  mobileImageWidth: number;
  desktopImageWidth: number;
  aspectRatio: number;
};

type FullImgContainerProps = {
  mobileImageWidth: number;
  desktopImageWidth: number;
};

type PageHeroButtonProps = {
  notificationBarIsActive: boolean | null;
  notificationBarHeight: number;
  topNavIsVisible?: boolean;
};

export const PageHero = styled(Base)<PageHeroProps>`
  min-height: ${({ aspectRatio, mobileImageWidth }) =>
    !!mobileImageWidth && `${Math.round(mobileImageWidth / aspectRatio)}px`};

  ${Theme.mediaQueries.sm} {
    min-height: ${({ aspectRatio, desktopImageWidth }) =>
      !!desktopImageWidth &&
      `${Math.round(desktopImageWidth / aspectRatio)}px`};
  }
`;

export const FullImgContainer = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})<FullImgContainerProps>`
  left: 50%;
  width: ${({ mobileImageWidth }) => `${mobileImageWidth}px`};
  margin-left: ${({ mobileImageWidth }) =>
    `calc(${mobileImageWidth}px / 2 * -1)`};
  opacity: 0.3;

  ${Theme.mediaQueries.sm} {
    width: ${({ desktopImageWidth }) => `${desktopImageWidth}px`};
    margin-left: ${({ desktopImageWidth }) =>
      `calc(${desktopImageWidth}px / 2 * -1)`};
    opacity: 1;
  }
`;

const calculateTop = (
  notificationBarIsActive: boolean | null,
  notificationBarHeight: number,
  topNavIsVisible: boolean
) => {
  if (notificationBarIsActive && topNavIsVisible) {
    return `calc(${notificationBarHeight}px + ${Theme.sizes.topBarHeightTablet} + 1.5rem)`;
  } else if (notificationBarIsActive && !topNavIsVisible) {
    return `calc(${notificationBarHeight}px + 1.5rem)`;
  } else if (!notificationBarIsActive && topNavIsVisible) {
    return `calc(${Theme.sizes.topBarHeightTablet} + 1.5rem)`;
  } else {
    return '1.5rem';
  }
};

export const PageHeroButton = styled(LinkOrButton).attrs(
  ({
    notificationBarIsActive,
    notificationBarHeight,
    topNavIsVisible = true,
  }: PageHeroButtonProps) => ({
    // applies css to button container instead of inner
    style: {
      top: calculateTop(
        notificationBarIsActive,
        notificationBarHeight,
        topNavIsVisible
      ),
      transition: `top ${topNavIsVisible ? '300ms' : '200ms'} ease`,
    },
  })
)<PageHeroButtonProps>``;
