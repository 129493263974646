import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { ModuleHeader } from 'types';

export default memoize((moduleHeader: unknown): ModuleHeader => {
  return {
    title: get(moduleHeader, 'title', ''),
    description: get(moduleHeader, 'description', ''),
  };
});
