import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { EmphasizeModuleColorMap } from 'constants/ColorScheme';

import { IBlogCategoryPage } from 'types';
import sanitizeBlogPost from './sanitizeBlogPost';
import sanitizeBlogPostModules from './modules/sanitizeBlogPostModules';

export default memoize(
  (
    blogCategory: any,
    blogPostsInCategoryCount?: any,
    blogPostsInCategory?: any[],
  ): IBlogCategoryPage | null => {
    const id = get(blogCategory, '_id');

    if (!id) {
      return null;
    }

    return {
      id,
      type: blogCategory._type,
      slug: blogCategory.slug,
      title: blogCategory.title,
      hero: {
        image: sanitizeImage(blogCategory.heroImage),
        color:
          EmphasizeModuleColorMap[blogCategory.heroColor || '#ffffff'] ||
          'WHITE',
        imageBackgroundColor:
          blogCategory.heroImageBackgroundColor || '#ffffff',
        title: blogCategory.title || '',
        description: blogCategory.heroDescription || '',
      },
      blogPosts:
        blogPostsInCategory
          ?.map((post) => sanitizeBlogPost(post))
          .filter(Boolean) || [],
      blogPostCount: blogPostsInCategoryCount || 0,
      seo: {
        title: blogCategory.metaTitle || '',
        description: blogCategory.metaDescription || '',
        image: sanitizeImage(blogCategory.metaImage),
        structuredData: blogCategory.structuredData?.code || null,
        canonicalUrl: blogCategory.canonicalUrl || '',
      },
      modules: sanitizeBlogPostModules(blogCategory.moduleRepeater),
    };
  },
);
