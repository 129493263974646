import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeArticleBody from 'state/sanitizers/sanitizeArticleBody';
import sanitizeArticleLinks from 'state/sanitizers/sanitizeArticleLinks';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeAuthor from 'state/sanitizers/sanitizeAuthor';
import sanitizePauseUxCta from 'state/sanitizers/sanitizePauseUxCta';

import { EmphasizeModuleColorMap } from 'constants/ColorScheme';
import { RouteMap } from 'constants/Routes';

import { Article, ArticleUserStories } from 'types';
import sanitizeBrowsePageLinks from './sanitizeBrowsePageLinks';

export default memoize(
  (article: any, userStories: ArticleUserStories): Article | null => {
    const id = get(article, '_id');
    if (!id) {
      return null;
    }

    return {
      id,
      title: get(article, 'title', ''),
      slug: get(article, 'slug', ''),
      sxOverview: get(article, 'sxOverview', []),
      conditionName: get(article, 'conditionName', ''),
      type: get(article, '_type', 'article'),
      articleType: get(article, 'articleType', null),
      language: article.language || null,
      meta: {
        author: sanitizeAuthor(get(article, 'author')),
        reviewer: sanitizeAuthor(get(article, 'reviewer')),
        publishedDate:
          get(article, 'publishedDate', '') ||
          get(article, '_createdAt', '') ||
          '',
        // Temporary fix for modifiedDate not being set using _updatedAt when "Publish" button is clicked
        modifiedDate:
          get(article, 'modifiedDate', '') ||
          get(article, '_updatedAt', '') ||
          '',
      },
      hero: {
        image: sanitizeImage(get(article, 'heroImage')),
        subtitle: get(article, 'subtitle', ''),
        color:
          EmphasizeModuleColorMap[get(article, 'heroColor', '#ffffff')] ||
          'WHITE',
      },
      content: {
        articleUserStories: userStories,
        overviewSlug: get(article, 'overviewSlug', ''),
        overviewLabel: get(article, 'overviewSideNavLabel', ''),
        overview: get(article, 'overview', []),
        bodyModules: sanitizeArticleBody(
          get(article, 'articleBodyModuleRepeater', [])
        ),
        references: get(article, 'references', []),
        moreArticles: sanitizeArticleLinks(get(article, 'moreArticles', [])),
        // UGC data can be updated on the article page, so it should be excluded from this memoized sanitizer
        articleRating: {
          likes: 0,
          dislikes: 0,
        },
        hideUserStory: get(article, 'hideUserStory', false),
        promoteSupplier: !!article.articleBodyModuleRepeater.find(
          (module: any) => module?._type === 'supplierCtaModule'
        ),
      },
      disableAds: article.disableAds || false,
      seo: {
        canonicalUrl: get(article, 'canonicalUrl', ''),
        description: get(article, 'metaDescription', ''),
        image: sanitizeImage(get(article, 'metaImage')),
        keywords: get(article, 'keywords', []),
        noindex: get(article, 'noindex', false),
        structuredData: get(article, 'structuredData.code', null),
        title: get(article, 'metaTitle', ''),
      },
      symptomChecker: {
        buttonLabelMobile: get(
          article,
          'symptomCheckerButtonLabelMobile',
          'Take symptom quiz'
        ),
        buttonLabelDesktop: get(
          article,
          'symptomCheckerButtonLabelDesktop',
          'Take symptom quiz'
        ),
        buttonUrl: get(
          article,
          'symptomCheckerButtonUrl',
          RouteMap.SYMPTOM_CHECKER.path
        ),
        buttonGtmTrackerEventName: get(
          article,
          'symptomCheckerButtonGtmTrackerEventName',
          'tab-navigation-button-click'
        ),
        header: get(
          article,
          'symptomCheckerHeader',
          'Try our free symptom checker'
        ),
        description: get(
          article,
          'symptomCheckerDescription',
          'Get a thorough self-assessment before your visit to the doctor.'
        ),
      },
      taxonomy: article.dxTaxonomy || article.sxTaxonomy || [],
      testerRecruitment: {
        banner: {
          displayBanner: get(article, 'displayTesterRecruitmentBanner', false),
          mainCta: get(article, 'testerRecruitmentBannerMainCta', ''),
          supportingCta: get(
            article,
            'testerRecruitmentBannerSupportingCta',
            ''
          ),
        },
        popUp: {
          title: get(article, 'testerRecruitmentPopUpTitle', ''),
          description: get(article, 'testerRecruitmentPopUpDescription', []),
          subtitle: get(article, 'testerRecruitmentPopUpSubtitle', ''),
          ctaLabel: get(article, 'testerRecruitmentPopUpCtaLabel', ''),
        },
      },
      pauseUxCta: sanitizePauseUxCta(article),
      firstAidBox: {
        title: get(article, 'firstAidBoxTitle', 'What else you should know'),
        description: get(article, 'firstAidBoxDescription', []),
        backgroundColor: get(article, 'firstAidBoxBackgroundColor', '#ecf2fe'),
        thinkSectionTitle: get(
          article,
          'firstAidBoxThinkSectionTitle',
          'Think'
        ),
        thinkSectionDescription: get(
          article,
          'firstAidBoxThinkSectionDescription',
          []
        ),
        checkSectionTitle: get(
          article,
          'firstAidBoxCheckSectionTitle',
          'Check'
        ),
        checkSectionDescription: get(
          article,
          'firstAidBoxCheckSectionDescription',
          []
        ),
        doSectionTitle: get(article, 'firstAidBoxDoSectionTitle', 'Do'),
        doSectionDescription: get(
          article,
          'firstAidBoxDoSectionDescription',
          []
        ),
        urgentSectionTitle: get(
          article,
          'firstAidBoxUrgentSectionTitle',
          'Urgent'
        ),
        urgentSectionDescription: get(
          article,
          'firstAidBoxUrgentSectionDescription',
          []
        ),
        supportSectionTitle: get(
          article,
          'firstAidBoxSupportSectionTitle',
          'Support'
        ),
        supportSectionDescription: get(
          article,
          'firstAidBoxSupportSectionDescription',
          []
        ),
        ctaLabel: get(article, 'firstAidBoxCtaLabel', ''),
        ctaUrl: get(article, 'firstAidBoxCtaUrl', ''),
      },
      treatmentsPage: get(article, 'treatmentsPage', null),
      browsePageLinks: sanitizeBrowsePageLinks(
        get(article, 'browsePageLinks', []),
        get(article, 'articleType', null)
      ),
      translatedPages: get(article, 'translatedPages', []),
    };
  }
);
