import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeSideNavOption from 'state/sanitizers/articleBodyModules/sanitizeSideNavOption';
import sanitizeContentTag from '../modules/sanitizeContentTag';

import type { ArticleText } from 'types';

export default memoize((module: any): ArticleText => {
  return {
    type: get(module, '_type', 'articleText'),
    id: get(module, '_key', ''),
    sideNav: sanitizeSideNavOption(module),
    addToSeoFaqJsonSchema: get(module, 'addToSeoFaqJsonSchema', false),
    text: get(module, 'text', []),
    contentTag: sanitizeContentTag(module.contentTag || {}),
    title: module.contentTitle || '',
  };
});
