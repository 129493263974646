import React, { FC } from 'react';
import Head from 'next/head';
import BlockContent from '@sanity/block-content-to-react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { submitOnboardingForm } from 'state/actions/formActions';

import { Form, BasePortableText } from 'styled';

import useLanguage from 'hooks/useLanguage';
import {
  ExternalLink,
  InternalLink,
  ListItem,
} from 'constants/PortableTextSerializer';

import {
  GlobalState,
  FormValues,
  GeneralInquiry,
  GeneralInquiryModule as IGeneralInquiryModule,
} from 'types';
import { useRouter } from 'next/router';
import sanitizeQueryParamToString from 'utils/sanitizeQueryParamToString';
import { useTheme } from 'styled-components';

interface PassedProps {
  generalInquiry: GeneralInquiry;
  module: IGeneralInquiryModule;
}

type Props = PassedProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const GeneralInquiryModule: FC<Props> = ({
  generalInquiry,
  module,
  submitOnboardingFormStatus,
  actions,
}) => {
  const Language = useLanguage();
  const { palette } = useTheme();
  const router = useRouter();
  const { sectionTitle, sectionDescription, formDescription, inputFields } =
    generalInquiry;
  const { formLeadSource, formSubmissionMethod } = module;
  const { submitOnboardingForm } = actions;

  const handleFormSubmit = (formValues: FormValues) => {
    const lead: {
      [key: string]: string;
    } = {
      ...formValues,
    };

    if (formLeadSource) lead.lead_source = formLeadSource;

    lead.Lead_Source_Other__c = `/${sanitizeQueryParamToString(
      router.query.slug,
    )}`;

    submitOnboardingForm(lead, formSubmissionMethod);
  };

  return (
    <>
      {/* Load Chili Piper script file */}
      {formSubmissionMethod === 'chili-piper' && (
        <Head>
          <script
            defer
            src="https://js.chilipiper.com/marketing.js"
            type="text/javascript"
          />
        </Head>
      )}

      <div className="GeneralInquiryModule z-overlay w100 module-content-padding-x py6 relative justify-center xl:flex">
        <div className="flex-col xl:inline-flex">
          <div className="mb_75 text-lg">{sectionTitle}</div>
          <div className="mt3_5 md:mt6 flex flex-col md:flex-row">
            <BasePortableText
              textColor={palette.text.primary}
              className="GeneralInquiryModule__section-description text-sm"
            >
              <BlockContent
                blocks={sectionDescription}
                serializers={{
                  marks: { link: ExternalLink, internalLink: InternalLink },
                  listItem: ListItem,
                }}
              />
            </BasePortableText>
            <div className="md:ml5_5 xl:ml6 flex-1">
              {!!formDescription && (
                <div className="Form__description my3 md:my0">
                  <span className="text-sm">{formDescription}</span>
                </div>
              )}
              <Form
                className="GeneralInquiryModule__form w100"
                color={palette.gray[60]}
                inputFields={inputFields}
                handleSubmit={(formValues: FormValues) => {
                  handleFormSubmit(formValues);
                }}
                formSubmitStatus={submitOnboardingFormStatus}
                submitButtonLabel={Language.t(
                  'GeneralInquiryModule.sendButtonLabel',
                )}
                title="GeneralInquiryModule"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  submitOnboardingFormStatus: state.status.submitOnboardingFormStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      submitOnboardingForm,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInquiryModule);
