import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { SplitCtaModule } from 'types';

export default memoize((module: unknown): SplitCtaModule => {
  return {
    type: get(module, '_type', 'splitCta'),
    id: get(module, '_key', ''),
    title: get(module, 'contentTitle', ''),
    description: get(module, 'contentDescription', ''),
    downloadLink: sanitizeInternalLink(get(module, 'downloadLink', '')),
    slug: get(module, 'slug', ''),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
