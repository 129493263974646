import React, { FC } from 'react';
import Head from 'next/head';
import { BlogPost, BlogPostModule, TextModule } from 'types';
import {
  getAnswer,
  getQuestion,
} from 'utils/extractQuestionAndAnswerFromTextModule';

interface Props {
  blogPost: BlogPost;
}

const BlogPostStructuredDataSchema: FC<Props> = ({ blogPost }) => {
  const { meta, seo, slug, modules } = blogPost;
  const { author, publishedDate } = meta;
  const blogCategorySlug = blogPost.categories?.[0] || '';

  const getBlogPostTextModulesWithSeoFaqEnabled = (
    modules: BlogPostModule[]
  ): TextModule[] | [] => {
    if (!modules) return [];

    const blogPostTextArray: TextModule[] = modules.reduce(
      (textModules: TextModule[], module: BlogPostModule) => {
        // only text modules with addToSeoFaqJsonSchema set to true should be returned
        if (module?.type === 'textModule' && module?.addToSeoFaqJsonSchema) {
          textModules.push(module);
        }

        return textModules;
      },
      []
    );

    return blogPostTextArray;
  };

  const baseSchemaJson = {
    '@context': 'https://schema.org',
    publisher: {
      '@type': 'Organization',
      name: 'Buoy Health, Inc.',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.buoyhealth.com/static/images/app_icons/ios-icon-192x192.png',
      },
    },
    '@type': 'MedicalWebPage',
    headline: seo.title,
    description: seo.description,
    author: author
      ? {
          '@type': 'Person',
          name: author.fullName,
          sameas: `https://www.buoyhealth.com/writers${author.slug}`,
        }
      : undefined,
    url: `https://www.buoyhealth.com/blog${blogCategorySlug || ''}${slug}`,
    datePublished: publishedDate,
    image: [seo.image.src],
  };

  const faqPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: getBlogPostTextModulesWithSeoFaqEnabled(modules)?.map(
      (textModule) => ({
        '@type': 'Question',
        name: getQuestion(textModule.contents),
        acceptedAnswer: {
          '@type': 'Answer',
          text: getAnswer(textModule.contents),
        },
      })
    ),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(baseSchemaJson),
        }}
      />
      {/* if addToSeoFaqJsonSchema is true, Q&A content will be added to the FAQPageSchema  */}
      {faqPageSchema.mainEntity.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faqPageSchema),
          }}
        />
      )}
    </Head>
  );
};

export default BlogPostStructuredDataSchema;
