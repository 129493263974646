import memoize from 'lodash/memoize';
import { QuestionType } from '@buoy-components/polaris/types';
import type {
  IInterviewQuestion,
  IInterviewOption,
} from '@buoy-components/polaris/types';

function sanitizeQuestionType(questionType: string): QuestionType {
  switch (questionType) {
    case 'single':
      return QuestionType.Single;
    case 'profile':
      return QuestionType.Profile;
    default:
      return QuestionType.MultipleChoice;
  }
}
// Sanitize mini interview questions so the structure matches what is expected in the Mini interview component
export default memoize(
  (question: any): Omit<IInterviewQuestion, 'media' | 'media_alttext'> => {
    return {
      id: question._id,
      key: question.type || 'uniqueQA',
      choice: sanitizeQuestionType(question.configuration?.questionType),
      configuration: {
        answerRequirements: question.configuration?.answerRequirements || '',
        partner: question.configuration?.partner || '',
        interview: question.configuration?.interview || '',
      },
      displayList: question.list || [],
      text: question.text || '',
      subText: question.subText || '',
      options:
        question.options?.map((option: any): IInterviewOption => {
          return {
            exclusive: option.other || null,
            next: option.next || null,
            only: option.only || null,
            outcome: option.outcome || null,
            subText: option.subText || '',
            text: option.text,
            token: option._key,
            type: option.type || '',
            valid: option.valid || null,
          };
        }) || [],
    };
  },
);
