import React, { FC } from 'react';

import get from 'lodash/get';

import {
  AccordionListModule,
  AccordionListWithBlueAccentsModule,
  AnimationAndTextModule,
  BannerModule,
  CalloutModuleWrapper,
  CarouselModule,
  EmphasisLinkCardModule,
  FourColumnVideoModule,
  GeneralInquiryModule,
  GoBackModule,
  HighlightedImagesModule,
  ImageCardsModule,
  InstagramFeedModule,
  JobPostingsModule,
  LargeTextModule,
  LinkCardsModule,
  LinkCardWithImageModule,
  LinksListModule,
  ListItemsAndLogosModule,
  LogosModule,
  NumberedListWithImageModule,
  QuoteModule,
  SingleBlockCtaModule,
  SliderModule,
  SplitCtaModule,
  TeamListModule,
  TextModule,
  ThreeColumnInfoModule,
  ThreeColumnAnimationModule,
  ThumbnailCardsModule,
  VideoModule,
  VideoAndListItemsModule,
  VideoCtaModule,
} from 'components/modules';

import GoogleFormModule from 'styled/components/modules/GoogleFormModule';
import InsuranceFormModule from 'styled/components/modules/InsuranceForm';
import SupplierCtaModule from 'styled/components/modules/SupplierCtaModule';
import TypeformModule from './modules/TypeformModule';

import {
  EmphasizeModuleColor,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import { Color } from 'styled/theme/colors';

import { Module, ShowcasePageModule } from 'types';
import { GlobalSettingsReducer as GlobalSettings } from 'state/reducers/globalSettings';

interface Props {
  modules: Module[] | ShowcasePageModule[];
  globalSettings?: GlobalSettings;
  hasWrapper?: boolean;
  isGenericPage?: boolean;
  color?: EmphasizeModuleColor | GeneralModuleColor;
  textColor?: Color;
  setVideoPopUpIsActive?(id: string, title?: string): void;
}

const ModuleSwitch: FC<Props> = ({
  modules,
  globalSettings,
  hasWrapper = false,
  isGenericPage,
  color,
  textColor,
  setVideoPopUpIsActive,
}) => (
  <>
    {modules.map((module) => {
      if (!module) {
        return null;
      }

      const calloutIsActive = get(
        module,
        'moduleOptions.callout.isActive',
        false
      );

      switch (module.type) {
        case 'accordionList':
          if (calloutIsActive && !hasWrapper) {
            return (
              <CalloutModuleWrapper
                key={module.id}
                callout={module.moduleOptions.callout}
              >
                <AccordionListModule
                  module={module}
                  hasCallout
                  heroTextColor={textColor}
                />
              </CalloutModuleWrapper>
            );
          }

          return (
            <AccordionListModule
              key={module.id}
              module={module}
              heroTextColor={textColor}
            />
          );
        case 'accordionListWithAnimation':
          return (
            <AccordionListWithBlueAccentsModule
              key={module.id}
              module={module}
            />
          );
        case 'animationAndTextModule':
          return <AnimationAndTextModule key={module.id} module={module} />;
        case 'banner':
          return <BannerModule key={module.id} module={module} />;
        case 'carousel':
          return <CarouselModule key={module.id} module={module} />;
        case 'emphasisLinkCard':
          if (calloutIsActive && !hasWrapper) {
            return (
              <CalloutModuleWrapper
                callout={module.moduleOptions.callout}
                key={module.id}
              >
                <EmphasisLinkCardModule module={module} hasCallout />
              </CalloutModuleWrapper>
            );
          }

          return <EmphasisLinkCardModule key={module.id} module={module} />;
        case 'fourColumnVideo':
          return (
            <FourColumnVideoModule
              key={module.id}
              module={module}
              setVideoPopUpIsActive={setVideoPopUpIsActive}
            />
          );
        case 'generalInquiry':
          if (globalSettings && 'generalInquiry' in globalSettings) {
            return (
              <GeneralInquiryModule
                key={module.id}
                generalInquiry={globalSettings.generalInquiry}
                module={module}
              />
            );
          }

          return null;
        case 'goBack':
          return <GoBackModule key={module.id} module={module} />;
        case 'googleFormModule':
          return <GoogleFormModule key={module.id} module={module} />;
        case 'insuranceFormModule':
          return <InsuranceFormModule key={module.id} module={module} />;
        case 'highlightedImages':
          return <HighlightedImagesModule key={module.id} module={module} />;
        case 'imageCards':
          return <ImageCardsModule key={module.id} module={module} />;
        case 'jobPostings':
          return <JobPostingsModule key={module.id} module={module} />;
        case 'largeText':
          return <LargeTextModule key={module.id} module={module} />;
        case 'linksList':
          return <LinksListModule key={module.id} module={module} />;
        case 'linkCards':
          return <LinkCardsModule key={module.id} module={module} />;
        case 'linkCardWithImage':
          return <LinkCardWithImageModule key={module.id} module={module} />;
        case 'listItemsAndLogosModule':
          return <ListItemsAndLogosModule key={module.id} module={module} />;
        case 'logos':
          return <LogosModule key={module.id} module={module} />;
        case 'instagramFeed':
          return <InstagramFeedModule key={module.id} module={module} />;
        case 'numberedListWithImage':
          return (
            <NumberedListWithImageModule
              key={module.id}
              module={module}
              hasWrapper={hasWrapper}
              wrapperColor={color as GeneralModuleColor}
            />
          );
        case 'quote':
          return <QuoteModule key={module.id} module={module} />;
        case 'singleBlockCta':
          return (
            <SingleBlockCtaModule
              key={module.id}
              module={module}
              hasWrapper={hasWrapper}
              wrapperColor={color as EmphasizeModuleColor}
            />
          );
        case 'slider':
          return <SliderModule key={module.id} module={module} />;

        case 'splitCta':
          return <SplitCtaModule key={module.id} module={module} />;
        case 'supplierCtaModule':
          return <SupplierCtaModule key={module.id} module={module} />;
        case 'teamList':
          return <TeamListModule key={module.id} module={module} />;
        case 'textModule':
          if (calloutIsActive && !hasWrapper) {
            return (
              <CalloutModuleWrapper
                key={module.id}
                anchor={module.anchor}
                callout={module.moduleOptions.callout}
              >
                <TextModule
                  module={module}
                  hasWrapper={calloutIsActive}
                  calloutVariant={module.moduleOptions.callout.variant}
                  hasCallout
                  className="module-content-padding-x"
                />
              </CalloutModuleWrapper>
            );
          }

          return (
            <TextModule
              key={module.id}
              module={module}
              hasWrapper={hasWrapper}
              heroTextColor={textColor}
              className="module-content-padding-x"
            />
          );
        case 'threeColumnAnimationModule':
          return <ThreeColumnAnimationModule key={module.id} module={module} />;
        case 'threeColumnInfo':
          return (
            <ThreeColumnInfoModule
              key={module.id}
              module={module}
              hasWrapper={hasWrapper}
              wrapperColor={color as EmphasizeModuleColor}
            />
          );
        case 'thumbnailCards':
          return <ThumbnailCardsModule key={module.id} module={module} />;
        case 'typeformModule':
          return (
            <TypeformModule
              key={module.id}
              addModulePadding={!isGenericPage}
              module={module}
            />
          );
        case 'videoModule':
          return <VideoModule key={module.id} module={module} />;
        case 'videoAndListItemsModule':
          return <VideoAndListItemsModule key={module.id} module={module} />;
        case 'videoCtaModule':
          return <VideoCtaModule key={module.id} module={module} />;
        default:
          return null;
      }
    })}
  </>
);

export default ModuleSwitch;
