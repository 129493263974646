import { FC } from 'react';

import { Img } from 'styled';
import { Container, Caption } from './style';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { ImageWithCaptionModule as IImageWithCaptionModule } from 'types';

export interface Props {
  module: IImageWithCaptionModule;
}

const ImageWithCaptionModule: FC<Props> = ({ module }) => {
  const { image, caption } = module;

  return (
    <Container
      showBorderTop={module.moduleOptions.showBorderTop}
      showBorderBottom={module.moduleOptions.showBorderBottom}
    >
      <Img
        alt={image.alt}
        sizes="(max-width: 768px) 1440px, (max-width: 1024px) 1952px, 1500px"
        src={sanityImgUtil(image, 1440)}
        srcSet={sanityImgSrcSetUtil(image, 1440, 1952, 1500)}
        preloadFullWidth={true}
        dimensions={image.metadata?.dimensions}
        crop={image.crop}
      />
      {!!caption && <Caption>{caption}</Caption>}
    </Container>
  );
};

export default ImageWithCaptionModule;
