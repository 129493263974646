import React from 'react';
import { Component, RefObject, createRef } from 'react';
import theme from '@buoyhealth/common.buoy-theme';

import get from 'lodash/get';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import { Video } from 'styled';
import { ItemContainer } from '../style';
import { Video as StyledVideo } from './style';
import ItemHeader from '../ItemHeader';
import ItemDescription from '../ItemDescription';

import { Color } from 'styled/theme/colors';

import { VideoItem as IVideoItem } from 'types';

interface PassedProps {
  item: IVideoItem;
  bgColor: Color;
  textColor: Color;
}

interface State {
  descriptionHeight: number;
}

type Props = WithBreakpointsProps & PassedProps;

class VideoItem extends Component<Props, State> {
  descriptionDiv: RefObject<HTMLDivElement> = createRef();

  state: State = {
    descriptionHeight: 0,
  };

  componentDidMount() {
    this.setState({
      descriptionHeight: get(this, 'descriptionDiv.current.clientHeight', 0),
    });
  }

  render() {
    const { item, bgColor, textColor } = this.props;
    const { descriptionHeight } = this.state;

    return (
      <ItemContainer position="relative">
        <StyledVideo
          as={Video}
          position="absolute"
          descriptionHeight={descriptionHeight}
          video={item.video}
        />
        <ItemContainer position="relative">
          <ItemHeader
            subtitle={item.subtitle}
            title={item.title}
            button={item.button}
            textColor={theme.palette.common.white}
          />
          <ItemDescription
            elemRef={this.descriptionDiv}
            button={item.button}
            description={item.description}
            textColor={textColor}
            bgColor={bgColor}
          />
        </ItemContainer>
      </ItemContainer>
    );
  }
}

export default withBreakpoints(VideoItem);
