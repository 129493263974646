import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeTeamMember from 'state/sanitizers/sanitizeTeamMember';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { TeamListModule } from 'types';

export default memoize((module: unknown): TeamListModule => {
  return {
    type: get(module, '_type', 'teamList'),
    id: get(module, '_key', ''),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    contents: get(module, 'contents', []).map((teamMember: unknown) => {
      const type = get(teamMember, '_type', '');

      if (type === 'author') {
        const moduleImage = sanitizeImage(get(teamMember, 'moduleImage'));

        return {
          ...sanitizeTeamMember(teamMember),
          type,
          jobTitle: get(teamMember, 'specialty', ''),
          image: moduleImage.src
            ? moduleImage
            : sanitizeImage(get(teamMember, 'image')),
        };
      }

      return {
        ...sanitizeTeamMember(teamMember),
        type,
      };
    }),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
