import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { LargeTextModule } from 'types';

import { FontSizes } from 'styled/theme/typography';

export default memoize((module: unknown): LargeTextModule => {
  return {
    type: get(module, '_type', 'largeText'),
    id: get(module, '_key', ''),
    text: get(module, 'text', ''),
    desktopFontSize: get(module, 'desktopFontSize', FontSizes.xl),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
