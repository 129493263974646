import memoize from 'lodash/memoize';
import get from 'lodash/get';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { GoBackModule } from 'types';

export default memoize((module: unknown): GoBackModule => {
  return {
    id: get(module, '_key', ''),
    type: get(module, '_type', 'goBack'),
    mobileImage: sanitizeImage(get(module, 'mobileImage')),
    desktopImage: sanitizeImage(get(module, 'desktopImage')),
    backButtonLabel: get(module, 'backButtonLabel', ''),
    moduleTitle: get(module, 'moduleTitle', ''),
    moduleDescription: get(module, 'moduleDescription', []),
    moreDetails: get(module, 'moreDetails', []),
    ctaSectionText: get(module, 'ctaSectionText', []),
    ctaButtonLabel: get(module, 'ctaButtonLabel', ''),
    ctaButtonLink: get(module, 'ctaButtonLink', ''),
    ctaButtonVariant: get(module, 'ctaButtonVariant', 'symptom-checker-blue'),
  };
});
