import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { QuestionFamilies, QuestionSubTypes } from 'constants/SurveyMonkey';
import { SurveyPage, SurveyQuestion } from 'types';

export default memoize((data: unknown): SurveyPage[] => {
  return get(data, 'pages', []).map((page: unknown) => {
    return {
      id: get(page, 'id', ''),
      title: get(page, 'title', ''),
      description: get(page, 'description', ''),
      questions: get(page, 'questions', []).reduce(
        (sanitizedQuestions: SurveyQuestion[], question: unknown) => {
          const questionFamily = get(question, 'family', '');
          const questionSubType = get(question, 'subtype', '');

          const sanitizedQuestion: SurveyQuestion = {
            id: get(question, 'id', ''),
            family: questionFamily,
            subtype: questionSubType,
            heading: '',
            choices: [],
          };

          if (
            (questionFamily === QuestionFamilies.SINGLE_CHOICE ||
              questionFamily === QuestionFamilies.MULTIPLE_CHOICE) &&
            questionSubType === QuestionSubTypes.VERTICAL
          ) {
            sanitizedQuestion.heading = get(
              question,
              'headings[0].heading',
              ''
            );
            sanitizedQuestion.choices = get(
              question,
              'answers.choices',
              []
            ).map((choice: object) => ({
              id: get(choice, 'id', ''),
              text: get(choice, 'text', ''),
            }));
          }

          if (
            questionFamily === QuestionFamilies.MATRIX &&
            questionSubType === QuestionSubTypes.RATING
          ) {
            sanitizedQuestion.context = {
              id: get(question, 'answers.rows[0].id', ''),
              text: get(question, 'headings[0].heading', ''),
            };
            sanitizedQuestion.heading = get(
              question,
              'answers.rows[0].text',
              ''
            );
            sanitizedQuestion.choices = get(
              question,
              'answers.choices',
              []
            ).map((choice: object) => ({
              id: get(choice, 'id', ''),
              text: get(choice, 'text', ''),
            }));
          }

          return sanitizedQuestions.concat(sanitizedQuestion);
        },
        []
      ),
    };
  });
});
