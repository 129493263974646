import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeBrowsePageLink from 'state/sanitizers/sanitizeBrowsePageLink';
import { ArticleType, IBrowsePageLink } from 'types';

export default memoize(
  (browseLinks: any, articleType: ArticleType | null): IBrowsePageLink[] => {
    if (!get(browseLinks, 'length', 0)) return [];

    const sanitizedBrowsePageLinks = (browseLinks as any[]).map(
      (browseLink: any) => sanitizeBrowsePageLink(browseLink, articleType)
    );

    return sanitizedBrowsePageLinks;
  }
);
