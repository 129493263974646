import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import getDefaultArticleUrl from 'utils/getDefaultArticleUrl';

import { LinksListModule, LinksListItem } from 'types';

export default memoize((module: unknown): LinksListModule => {
  const items: LinksListItem[] = get(module, 'contents', []).map(
    (item: any) => {
      const type = get(item, '_type', '');

      if (type === 'article') {
        return {
          type,
          id: get(item, '_id', ''),
          // Client requested render keywords instead of title.
          title:
            get(item, 'conditionName', '') ||
            get(item, 'moduleTitle', '') ||
            get(item, 'title', ''),
          url: getDefaultArticleUrl({
            slug: item.slug,
            language: item.language,
            articleType: item.articleType,
          }),
          // Client requested articles in this module not to have description or button label.
          description: '',
          buttonLabel: '',
        };
      }

      return {
        type: 'manualContent',
        id: get(item, '_key', ''),
        title: get(item, 'title', ''),
        url: sanitizeInternalLink(get(item, 'url', '')),
        description: get(item, 'description', ''),
        buttonLabel: get(item, 'buttonLabel', 'Read'),
      };
    },
  );

  return {
    type: get(module, '_type', 'linksList'),
    id: get(module, '_key', ''),
    anchor: get(module, 'anchor', ''),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    contents: items,
    moduleOptions: sanitizeModuleOptions(module),
  };
});
