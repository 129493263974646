import type {
  FormLocation,
  IInterviewConfig,
  MiniInterviewType,
  IMiniInterviewProps,
  ITxocProps,
  ISupplierCardConfig,
} from '@buoy-components/polaris/types';

type InterviewConfigurationOptions = {
  interviewType: MiniInterviewType;
  interviewProps: Omit<IMiniInterviewProps, 'interviewType'> & {
    supplierCtaCardConfigs?: ISupplierCardConfig[];
  };
  options?: Record<string, any>;
};
type MachineVariant = 'default' | 'sxtg';
const SXTG: MachineVariant = 'sxtg';
const DEFAULT: MachineVariant = 'default';
const POPUP: FormLocation = 'polaris-popup';
const EMBEDDED: FormLocation = 'form-embedded';
/*
 * Returns the interview configuration required for interview to run.
 * This includes the props for the interview type as well as additional context.
 * Additional context is passed into the initial context of the interview machine in Polaris App.
 */
export default class InterviewConfigurationFactory {
  static buildInterviewConfiguration(
    interviewConfigOptions: InterviewConfigurationOptions,
  ): IInterviewConfig {
    const { interviewType, options, interviewProps } = interviewConfigOptions;
    const { formLocation, onQuestionSubmit, polarisProps, slug } =
      interviewProps;
    const sharedConfig = {
      formLocation: formLocation || options?.isEmbedded ? EMBEDDED : POPUP,
      onQuestionSubmit,
      polarisProps,
      slug,
      conditionName: interviewProps.conditionName,
    };

    switch (interviewType) {
      case 'emergency':
      case 'symptoms':
        return this.dynamicInterviewConfiguration(
          interviewConfigOptions,
          sharedConfig,
        );

      case 'dxoc':
        return this.dxocInterviewConfiguration(
          interviewConfigOptions,
          sharedConfig,
        );
      case 'treatment':
        return this.treatmentInterviewConfiguration(
          interviewConfigOptions,
          sharedConfig,
        );
      case 'treatmentGuidance':
        return this.treatmentGuidanceInterviewConfiguration(
          interviewConfigOptions,
          sharedConfig,
        );
      case 'txoc':
        return this.txocInterviewConfiguration(
          interviewConfigOptions,
          sharedConfig,
        );
      default:
        return {
          id: '',
          interviewType,
          context: sharedConfig,
        };
    }
  }

  static dynamicInterviewConfiguration(
    interviewConfigOptions: InterviewConfigurationOptions,
    sharedConfig: Record<string, any> = {},
  ) {
    const { interviewProps, interviewType, options } = interviewConfigOptions;
    const { complaints, conditionName } = interviewProps;
    return {
      interviewType,
      context: {
        complaints,
        conditionName,
        variant: options?.isSxTreatmentGuidance ? SXTG : DEFAULT,
        ...sharedConfig,
      },
      id: '',
    };
  }

  static dxocInterviewConfiguration(
    interviewConfigOptions: InterviewConfigurationOptions,
    sharedConfig: Record<string, any> = {},
  ) {
    const { interviewProps, interviewType } = interviewConfigOptions;
    const { complaints, conditionName } = interviewProps;
    return {
      interviewType,
      context: {
        conditionName,
        dxocs: complaints,
        ...sharedConfig,
      },
      id: '',
    };
  }

  static treatmentGuidanceInterviewConfiguration(
    interviewConfigOptions: InterviewConfigurationOptions,
    sharedConfig: Record<string, any> = {},
  ) {
    const { interviewProps, interviewType, options } = interviewConfigOptions;
    const { questions } = interviewProps;
    return {
      interviewType,
      context: {
        ...(options?.isSxTreatmentGuidance ? {} : { questions }),
        variant: options?.isSxTreatmentGuidance ? SXTG : DEFAULT,
        ...sharedConfig,
      },
      id: '',
    };
  }

  static treatmentInterviewConfiguration(
    interviewConfigOptions: InterviewConfigurationOptions,
    sharedConfig: Record<string, any> = {},
  ) {
    const { interviewProps, interviewType, options } = interviewConfigOptions;
    const { commonCauses, conditionName } = interviewProps;
    return {
      interviewType,
      context: {
        commonCauses,
        conditionName,
        variant: options?.isSxTreatmentGuidance ? SXTG : DEFAULT,
        ...(options?.isSxTreatmentGuidance
          ? {
              // Extra options passed into interview machine context
              extra: {
                sxInterviewEnabled: false,
              },
            }
          : {}),
        ...sharedConfig,
      },
      id: '',
    };
  }

  static txocInterviewConfiguration(
    interviewConfigOptions: InterviewConfigurationOptions,
    sharedConfig: Record<string, any> = {},
  ) {
    const { interviewProps, interviewType } = interviewConfigOptions;
    const { intro, outcomeCopy, questions, treatment } =
      interviewProps as ITxocProps;
    return {
      interviewType,
      context: {
        intro,
        outcomeCopy,
        questions,
        supplierCtaCardConfigs: interviewProps.supplierCtaCardConfigs,
        treatment,
        ...sharedConfig,
      },
      id: '',
    };
  }
}
