import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { HighlightsModule, ModuleOptionsGeneralColor } from 'types';

export default memoize((module: unknown): HighlightsModule => {
  return {
    type: get(module, '_type', 'highlights'),
    id: get(module, '_key', ''),
    contents: get(module, 'contents', []).map((item: unknown) => {
      return {
        id: get(item, '_key', ''),
        icon: sanitizeImage(get(item, 'icon', '')),
        title: get(item, 'title', ''),
        text: get(item, 'text', []),
      };
    }),
    moduleOptions: sanitizeModuleOptions(module) as ModuleOptionsGeneralColor,
  };
});
