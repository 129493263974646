import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeAccordionListModule from 'state/sanitizers/modules/sanitizeAccordionListModule';
import sanitizeAccordionListWithBlueAccentsModule from 'state/sanitizers/modules/sanitizeAccordionListWithBlueAccentsModule';
import sanitizeAnimationAndTextModule from 'state/sanitizers/modules/sanitizeAnimationAndTextModule';
import sanitizeArticleButton from 'state/sanitizers/articleBodyModules/sanitizeArticleButton';
import sanitizeArticleText from 'state/sanitizers/articleBodyModules/sanitizeArticleText';
import sanitizeCarouselModule from 'state/sanitizers/modules/sanitizeCarouselModule';
import sanitizeCtaModule from 'state/sanitizers/articleBodyModules/sanitizeCta';
import sanitizeDxListModule from 'state/sanitizers/articleBodyModules/sanitizeDxListModule';
import sanitizeEmbeddedInterview from './modules/sanitizeEmbeddedInterview';
import sanitizeEmphasisLinkCardModule from 'state/sanitizers/modules/sanitizeEmphasisLinkCardModule';
import sanitizeFeaturedLink from 'state/sanitizers/modules/sanitizeFeaturedLink';
import sanitizeGoogleFormModule from './modules/sanitizeGoogleFormModule';
import sanitizeInsuranceFormModule from './modules/sanitizeInsuranceFormModule';
import sanitizeHighlightedImagesModule from 'state/sanitizers/modules/sanitizeHighlightedImagesModule';
import sanitizeHighlightedText from 'state/sanitizers/articleBodyModules/sanitizeHighlightedText';
import sanitizeImageCardsModule from 'state/sanitizers/modules/sanitizeImageCardsModule';
import sanitizeKatalysModule from './sanitizeKatalysModule';
import sanitizeLargeTextModule from 'state/sanitizers/modules/sanitizeLargeTextModule';
import sanitizeLinkCardsModule from 'state/sanitizers/modules/sanitizeLinkCardsModule';
import sanitizeLinkCardWithImageModule from 'state/sanitizers/modules/sanitizeLinkCardWithImageModule';
import sanitizeLinksListModule from 'state/sanitizers/modules/sanitizeLinksListModule';
import sanitizeListItemsAndLogosModule from 'state/sanitizers/modules/sanitizeListItemsAndLogosModule';
import sanitizeNumberedListWithImageModule from 'state/sanitizers/modules/sanitizeNumberedListWithImageModule';
import sanitizeQueryModule, {
  ISanityQueryModule,
} from 'state/sanitizers/modules/sanitizeQueryModule';
import sanitizeQuoteModule from 'state/sanitizers/modules/sanitizeQuoteModule';
import sanitizeRoundedBgLinkCardsModule from 'state/sanitizers/articleBodyModules/sanitizeRoundedBgLinkCardsModule';
import sanitizeSingleBlockCtaModule from 'state/sanitizers/modules/sanitizeSingleBlockCtaModule';
import sanitizeSliderModule from 'state/sanitizers/modules/sanitizeSliderModule';
import sanitizeSplitCtaModule from 'state/sanitizers/modules/sanitizeSplitCtaModule';
import sanitizeSupplierCtaModule from './modules/sanitizeSupplierCtaModule';
import sanitizeTesterRecruitmentCtaModule from 'state/sanitizers/modules/sanitizeTesterRecruitmentCtaModule';
import sanitizeThreeColumnAnimationModule from './modules/sanitizeThreeColumnAnimationModule';
import sanitizeVideoAndListItemsModule from 'state/sanitizers/modules/sanitizeVideoAndListItemsModule';
import sanitizeVideoCtaModule from 'state/sanitizers/modules/sanitizeVideoCtaModule';
import sanitizeTypeformModule from './modules/sanitizeTypeformModule';
import sanitizeVideoModule from './modules/sanitizeVideoModule';
import sanitizeSupplierCardsModule from './modules/sanitizeSupplierCardsModule';
import sanitizeProductCardsModule from './modules/sanitizeProductCardsModule';

import { ArticleBodyModule } from 'types';
import sanitizeImageWithCaptionModule from './modules/sanitizeImageWithCaptionModule';
import sanitizeHighlightsModule from './modules/sanitizeHighlightsModule';

export default memoize((modules: unknown[]): ArticleBodyModule[] => {
  return modules.map((module) => {
    const type = get(module, '_type');

    if (!type) {
      return null;
    }

    switch (type) {
      case 'accordionList':
        return sanitizeAccordionListModule(module);
      case 'accordionListWithAnimation':
        return sanitizeAccordionListWithBlueAccentsModule(module);
      case 'animationAndTextModule':
        return sanitizeAnimationAndTextModule(module);
      case 'articleButton':
        return sanitizeArticleButton(module);
      case 'articleText':
        return sanitizeArticleText(module);
      case 'carousel':
        return sanitizeCarouselModule(module);
      case 'cta':
        return sanitizeCtaModule(module);
      case 'dxList':
        return sanitizeDxListModule(module);
      case 'embeddedInterview':
        return sanitizeEmbeddedInterview(module);
      case 'emphasisLinkCard':
        return sanitizeEmphasisLinkCardModule(module);
      case 'featuredLink':
        return sanitizeFeaturedLink(module);
      case 'firstAidBox':
        return {
          id: get(module, '_key', ''),
          type: get(module, '_type', 'firstAidBox'),
        };
      case 'googleFormModule':
        return sanitizeGoogleFormModule(module);
      case 'insuranceFormModule':
        return sanitizeInsuranceFormModule(module);
      case 'highlightedImages':
        return sanitizeHighlightedImagesModule(module);
      case 'highlightedText':
        return sanitizeHighlightedText(module);
      case 'katalysModule':
        return sanitizeKatalysModule(module);
      case 'imageCards':
        return sanitizeImageCardsModule(module);
      case 'largeText':
        return sanitizeLargeTextModule(module);
      case 'linkCards':
        return sanitizeLinkCardsModule(module);
      case 'linkCardWithImage':
        return sanitizeLinkCardWithImageModule(module);
      case 'linksList':
        return sanitizeLinksListModule(module);
      case 'listItemsAndLogosModule':
        return sanitizeListItemsAndLogosModule(module);
      case 'numberedListWithImage':
        return sanitizeNumberedListWithImageModule(module);
      case 'quote':
        return sanitizeQuoteModule(module);
      case 'queryModule':
        return sanitizeQueryModule(module as ISanityQueryModule);
      case 'roundedBgLinkCardsModule':
        return sanitizeRoundedBgLinkCardsModule(module);
      case 'slider':
        return sanitizeSliderModule(module);
      case 'singleBlockCta':
        return sanitizeSingleBlockCtaModule(module);
      case 'splitCta':
        return sanitizeSplitCtaModule(module);
      case 'supplierCtaModule':
        return sanitizeSupplierCtaModule(module);
      case 'testerRecruitmentCta':
        return sanitizeTesterRecruitmentCtaModule(module);
      case 'threeColumnAnimationModule':
        return sanitizeThreeColumnAnimationModule(module);
      case 'typeformModule':
        return sanitizeTypeformModule(module);
      case 'videoModule':
        return sanitizeVideoModule(module);
      case 'videoAndListItemsModule':
        return sanitizeVideoAndListItemsModule(module);
      case 'videoCtaModule':
        return sanitizeVideoCtaModule(module);
      case 'imageWithCaption':
        return sanitizeImageWithCaptionModule(module);
      case 'highlights':
        return sanitizeHighlightsModule(module);
      case 'supplierCards':
        return sanitizeSupplierCardsModule(module);
      case 'productCards':
        return sanitizeProductCardsModule(module);
      default:
        return null;
    }
  });
});
