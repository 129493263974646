import type { MiniInterviewType } from '@buoy-components/polaris/types';

export const POLARIS_EAGER_LOAD_FLAG = 'growth-public-site-eager-load-polaris';
export const PXOC_QUESTION_COPY_FLAG_DX =
  'growth-public-site-abtest-pxoc-dx-copy';
export const PXOC_QUESTION_COPY_FLAG_SX =
  'growth-public-site-abtest-pxoc-sx-copy';
export const TREATMENT_GUIDANCE_EMBEDDED_INTERVIEW_FLAG =
  'growth-public-site-treatment-guidance-embedded-interview';
export const TXOC_FLAG = 'growth-public-site-txoc-lifeforce';

export const MINIMUM_PRODUCTS_COUNT = 3;
export const PolarisSupportedInterviewTypes = [
  'dxoc',
  'treatmentGuidance',
  'sxTreatmentGuidance',
];
/**All interviews required for the interview of type [interviewType] to run
 * e.g. Sx Treatment Guidance requires interviews of type treatment, treatmentGuidance, and symptoms
 **/
export const requiredInterviewsMap: Record<string, MiniInterviewType[]> = {
  dxoc: ['dxoc'],
  sxTreatmentGuidance: ['treatment', 'treatmentGuidance', 'symptoms'],
  symptoms: ['symptoms'],
  emergency: ['emergency'],
  treatment: ['treatment'],
  treatmentGuidance: ['treatmentGuidance'],
  txoc: ['txoc'],
  pxoc: ['pxoc'],
};

// NOTE: SxTG initial rollout is restricted to a few pages.
// Within those pages, SxTG will be enabled for specific flows.

// TODO: Remove this when SxTG is rolled out to all pages/we have less restrictions

type SxTGFlowConfig = {
  sxFlowEnabled: boolean;
  sxDxFlowEnabled: boolean;
  enabledDxs: string[];
};
export const SX_TG_ENABLED_FLOWS: Record<string, SxTGFlowConfig> = {
  '/cough': {
    sxFlowEnabled: true,
    sxDxFlowEnabled: true,
    enabledDxs: ['Bronchitis'],
  },
  '/female-urethral-itchiness': {
    sxFlowEnabled: true,
    sxDxFlowEnabled: true,
    enabledDxs: ['Bacterial Vaginosis', 'Yeast Infection'],
  },
  '/rib-pain': {
    sxFlowEnabled: true,
    sxDxFlowEnabled: true,
    enabledDxs: ['Bronchitis', 'Acute Costochondritis'],
  },
};
