import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { InputField } from 'types';

export default memoize((inputField: unknown): InputField => {
  return {
    label: get(inputField, 'label', ''),
    title: get(inputField, 'title', ''),
    isRequired: get(inputField, 'required', false),
    type: get(inputField, 'type', ''),
    options: get(inputField, 'options', []),
  };
});
