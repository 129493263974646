import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeArticleLink from 'state/sanitizers/sanitizeArticleLink';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeCarouselItemOption from 'state/sanitizers/modules/sanitizeCarouselItemOption';

import { CarouselItem } from 'types';

export default memoize((items: unknown[]): CarouselItem[] => {
  return items.map((item) => {
    const type = get(item, '_type');

    if (!type) {
      return null;
    }

    switch (type) {
      case 'article':
        const article = sanitizeArticleLink(item);

        if (!article) return null;

        return article;
      case 'textItem':
        return {
          type,
          ...sanitizeCarouselItemOption(item),
        };
      case 'quoteItem':
        const options = sanitizeCarouselItemOption(item);

        return {
          type,
          id: options.id,
          quote: get(item, 'quote', ''),
          attribution: get(item, 'attribution', []),
          description: options.description,
          button: options.button,
        };
      case 'imageItem':
        return {
          type,
          image: sanitizeImage(get(item, 'image')),
          ...sanitizeCarouselItemOption(item),
        };
      case 'videoItem':
        return {
          type,
          video: {
            vimeoId: get(item, 'videoId', ''),
          },
          ...sanitizeCarouselItemOption(item),
        };
      default:
        return null;
    }
  });
});
