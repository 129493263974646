import React, { FC } from 'react';
import Head from 'next/head';
import {
  Article,
  BlogPost,
  CampaignPage,
  DrugPage,
  GenericPage,
  IBlogCategoryPage,
  ProviderPage,
} from 'types';
import ArticleStructuredDataSchema from './ArticleStructuredDataSchema';
import BlogPostStructuredDataSchema from './BlogPostStructuredDataSchema';
import ProviderPageStructuredDataSchema from './ProviderPageStructuredDataSchema';

interface Props {
  page:
    | Article
    | BlogPost
    | IBlogCategoryPage
    | CampaignPage
    | DrugPage
    | GenericPage
    | ProviderPage;
}

/**
 * This component is used to render JSON structured data schema
 * for certain Sanity-generated pages. The SEO team can manually enter
 * custom schema through the Sanity CMS for specific pages.
 *
 * If a page contains custom schema, it will override the default.
 */
const StructuredDataSchema: FC<Props> = ({ page }) => {
  if (!page.seo?.structuredData) {
    switch (page.type) {
      case 'article':
        return <ArticleStructuredDataSchema article={page} />;
      case 'blogPost':
        return <BlogPostStructuredDataSchema blogPost={page} />;
      case 'provider':
        return <ProviderPageStructuredDataSchema providerPage={page} />;
      // TODO: Add default structured data schema for various page types
      default:
        return null;
    }
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: page.seo.structuredData,
        }}
      />
    </Head>
  );
};

export default StructuredDataSchema;
