import get from 'lodash/get';
import { GlobalSettingsId } from 'constants/SingletonDocumentId';
import BuoyClient from 'lib/BuoyClient';
import {
  ArticleLinkGroq,
  ArticleBodyModuleGroq,
  BlogCategoriesWithUnfrozenBlogsGroq,
  BlogFrozenGroq,
  DrugLinkGroq,
  ImageGroq,
  ModuleGroq,
} from 'lib/Groq';
import Sanity, { PreviewClient } from 'lib/SanityClient';
import sanitizeAllPages from 'state/sanitizers/sanitizeAllPages';
import sanitizeArticle from 'state/sanitizers/sanitizeArticle';
import sanitizeArticleLink from 'state/sanitizers/sanitizeArticleLink';
import sanitizeArticleGenericPage from 'state/sanitizers/sanitizeArticleGenericPage';
import sanitizeBlogPost from 'state/sanitizers/sanitizeBlogPost';
import sanitizeBlogPageCategory from 'state/sanitizers/sanitizeBlogPageCategory';
import sanitizeCampaignPage from 'state/sanitizers/sanitizeCampaignPage';
import sanitizeDrugPage from 'state/sanitizers/sanitizeDrugPage';
import sanitizeGenericPage from 'state/sanitizers/sanitizeGenericPage';
import sanitizeGlobalSettings from 'state/sanitizers/sanitizeGlobalSettings';
import sanitizeAuthorPage from 'state/sanitizers/sanitizeAuthorPage';
import sanitizeShowcasePage from 'state/sanitizers/sanitizeShowcasePage';
import sanitizeBrowsePage from 'state/sanitizers/sanitizeBrowsePage';
import sanitizeBrowsePageLinks from 'state/sanitizers/sanitizeBrowsePageLinks';
import sanitizeSlug from 'utils/sanitizeSlug';
import sanitizeQuiz from 'state/sanitizers/sanitizeQuiz';
import { BLOG_CATEGORY_PAGE_SIZE } from 'lib/constants';
import validateSchema from 'utils/validateSchema';
import { QuizResponseSchema } from 'lib/SanityClient/schemas/quiz';

import { GlobalSettingsReducer } from 'state/reducers/globalSettings';
import {
  Article,
  ArticleGenericPage,
  ArticleLink,
  ArticleType,
  AuthorPage,
  BlogPost,
  CampaignPage,
  DrugPage,
  Quiz,
  GenericPage,
  IBlogCategoryPage,
  IBrowsePage,
  IBrowsePageLink,
  QuizType,
  SanityDocument,
  ShowcasePage,
} from 'types';

import { sitemapDocs } from 'sitemap/queries';

// This client is used for Sanity content fetch actions

const ApiClient: {
  fetchArticle(options: {
    slug: string;
    preview?: string;
    language?: string;
    articleTypes?: ArticleType[];
  }): Promise<Article | null>;
  fetchBlogCategory(options: {
    filterByFrozen?: boolean;
    slug: string;
    page?: number | null;
  }): Promise<IBlogCategoryPage | null>;
  fetchBlogPost(options?: {
    category?: string;
    filterByFrozen?: boolean;
    slug: string;
    preview?: string;
    page?: number | null;
  }): Promise<BlogPost | IBlogCategoryPage | null>;
  fetchBlogPosts(options?: {
    count?: number | null;
    filterByFrozen?: boolean;
    onlyFeatured?: boolean;
  }): Promise<BlogPost[] | null>;
  fetchBlogCategories(options?: {
    filterByFrozen?: boolean;
  }): Promise<IBlogCategoryPage[] | null>;
  fetchDrugPage(slug: string, preview?: string): Promise<DrugPage | null>;
  fetchCampaignPage(
    slug: string,
    preview?: string,
  ): Promise<CampaignPage | null>;
  fetchGenericPage(
    slug: string,
    preview?: string,
  ): Promise<GenericPage | ArticleGenericPage | null>;
  fetchGlobalSettings(): Promise<GlobalSettingsReducer>;
  fetchAuthorPage(slug: string, preview?: string): Promise<AuthorPage | null>;
  fetchShowcasePage(
    slug: string,
    preview?: string,
  ): Promise<ShowcasePage | null>;
  fetchAllPages(): Promise<SanityDocument[]>;
  fetchBrowsePage(
    slug: string,
    type: ArticleType,
    preview?: string,
  ): Promise<IBrowsePage | null>;
  fetchBrowsePageLinks(
    taxonomy: string[],
    type: ArticleType | null,
    preview?: string,
  ): Promise<IBrowsePageLink[] | []>;
  fetchDxArticles(): Promise<ArticleLink[] | null>;
  fetchSxArticles(): Promise<ArticleLink[] | null>;
  fetchOtherArticles(): Promise<ArticleLink[] | null>;
  fetchCostArticles(): Promise<ArticleLink[] | null>;
  fetchQuiz(slug: string): Promise<Quiz | null>;
  fetchQuizzes(type: QuizType): Promise<Quiz[] | null>;
} = {
  async fetchArticle({ slug, preview = '', language, articleTypes }) {
    const groq = `{
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'heroImage': heroImage${ImageGroq},
      'heroImage': heroImage${ImageGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ image${ImageGroq}, ... },
      'reviewer': reviewer->{ image${ImageGroq}, ... },
      'moreArticles': moreArticles[]->${ArticleLinkGroq},
      'articleBodyModuleRepeater': articleBodyModuleRepeater[] ${ArticleBodyModuleGroq},
      'moduleImage': moduleImage${ImageGroq},
      'pauseUxCtaImage': pauseUxCtaImage${ImageGroq},
      'pauseUxCtaItems': pauseUxCtaItems[] { image${ImageGroq}, ... },
      'treatmentsPage': *[_type == 'treatmentsPage' && references(^._id)][0],
      'translatedPages': translatedPages[]->{ language, slug },
      ...
    }`;
    const isInternational = language && language !== 'en';
    const languageGroq = language
      ? `&& language == '${isInternational ? `${language}` : 'en'}'`
      : '';
    const articleTypeGroq = articleTypes
      ? `&& articleType in ["${articleTypes.join('","')}"]`
      : '';

    const articleQuery = `*[_type == 'article' && slug == ${sanitizeSlug(
      slug,
    )} ${languageGroq} ${articleTypeGroq}]| order(_updatedAt desc)[0] ${groq}`;

    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'article' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(articleQuery);
    const type = get(response, '_type');
    const id = get(response, '_id');

    if (type === 'article') {
      const userStories = await BuoyClient.getArticleUserStories(id, 3, 0);
      const sanitizedArticle = sanitizeArticle(response, userStories);
      const articleRating = await BuoyClient.getArticleOrDrugPageRatings(id);

      if (!sanitizedArticle) return null;

      const browsePageLinks = await this.fetchBrowsePageLinks(
        sanitizedArticle.taxonomy,
        sanitizedArticle.articleType,
        preview,
      );

      return {
        ...sanitizedArticle,
        browsePageLinks,
        content: {
          ...sanitizedArticle.content,
          articleRating,
        },
      };
    }

    return null;
  },
  async fetchBlogCategory(options) {
    const { filterByFrozen = false, slug, page = null } = options || {};
    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      ...
    }`;
    const blogPostGroq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ... },
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'categories': categories[]->{ ... },
      ...
    }`;
    const response: any = await Sanity.fetch(
      `*[_type == 'blogCategory' && slug == '${slug}'] ${groq}`,
    );
    if (response.length === 0) {
      return null;
    }
    const id = get(response[0], '_id');

    // Fetch paginated blog posts associated with this category
    const startingIndex = page ? BLOG_CATEGORY_PAGE_SIZE * (page - 1) : 0;
    const endingIndex = startingIndex + (BLOG_CATEGORY_PAGE_SIZE - 1);
    const blogPostsInCategory = (await Sanity.fetch(
      `*[_type == 'blogPost' && "${id}" in categories[]._ref ${BlogFrozenGroq(filterByFrozen)}] | order(_createdAt desc)[${startingIndex}..${endingIndex}] ${blogPostGroq}`,
    )) as unknown[];

    // Fetch total count of blog posts (will be used for pagination)
    const blogPostsInCategoryCount = await Sanity.fetch(
      `count(*[_type == 'blogPost' && "${id}" in categories[]._ref ${BlogFrozenGroq(filterByFrozen)}])`,
    );
    return sanitizeBlogPageCategory(
      response[0],
      blogPostsInCategoryCount,
      blogPostsInCategory,
    );
  },
  async fetchBlogPost(options) {
    const {
      category = '',
      filterByFrozen = false,
      page = null,
      preview = '',
      slug = '',
    } = options || {};

    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ... },
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'categories': categories[]->{ ... },
      ...
    }`;

    const categoryGroq = category
      ? `&& count((categories[] -> slug)[@ in["${category}"]]) > 0 `
      : '';

    const response: any = preview
      ? await PreviewClient.fetch(
          `*[(_type in ['blogPost']) && _id == '${preview}' ${categoryGroq} ${BlogFrozenGroq(filterByFrozen)}]| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[(_type in ['blogPost']) && slug == ${sanitizeSlug(
            slug,
          )} ${categoryGroq} ${BlogFrozenGroq(filterByFrozen)}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeBlogPost(response);
  },
  async fetchBlogPosts(options) {
    const {
      count = null,
      filterByFrozen = false,
      onlyFeatured = false,
    } = options || {};

    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ... },
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'categories': categories[]->{ ... },
      ...
    }`;
    const response: any = await Sanity.fetch(
      `*[_type == 'blogPost'${
        onlyFeatured ? '&& featured == true' : ''
      } ${BlogFrozenGroq(filterByFrozen)}]| order(_createdAt desc)${count ? `[0..${count - 1}]` : ''} ${groq}`,
    );
    return (
      response?.map((bp: any) => sanitizeBlogPost(bp)).filter(Boolean) || null
    );
  },
  async fetchBlogCategories(options) {
    const { filterByFrozen = true } = options || {};
    const groq = filterByFrozen
      ? `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      ...
    }`
      : BlogCategoriesWithUnfrozenBlogsGroq;
    const response: any = await Sanity.fetch(
      `*[_type == 'blogCategory']| order(title asc) ${groq}`,
    );
    return (
      response?.map((r: any) => sanitizeBlogPageCategory(r)).filter(Boolean) ||
      null
    );
  },
  async fetchDrugPage(slug, preview = '') {
    const groq = `{
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ..., image${ImageGroq} },
      'relatedDrugs': relatedDrugs[]->{ ... },
      'relatedDiagnoses': relatedDiagnoses[]->${ArticleLinkGroq},
      ...
    }`;

    const sanityResponse = preview
      ? await PreviewClient.fetch(
          `*[(_type == 'drug') && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[(_type == 'drug') && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    const id = preview || get(sanityResponse, '_id');
    const reviewResponse = await BuoyClient.getDrugReviews(id, 2, 0);
    const sideEffectResponse = await BuoyClient.getPopularDrugSideEffects(id);
    const pageRatings = await BuoyClient.getArticleOrDrugPageRatings(id);

    return sanitizeDrugPage(
      sanityResponse,
      reviewResponse,
      sideEffectResponse,
      pageRatings,
    );
  },
  async fetchCampaignPage(slug, preview = '') {
    const groq = `{
      ...,
      'metaImage': metaImage${ImageGroq},
      'image': image${ImageGroq},
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'campaignPage' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[_type == 'campaignPage' && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeCampaignPage(response);
  },
  async fetchGenericPage(slug, preview = '') {
    const groq = `{
      ...,
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'heroModuleRepeater': heroModuleRepeater[] ${ModuleGroq},
      'articleBodyModuleRepeater': articleBodyModuleRepeater[] ${ArticleBodyModuleGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[(_type == 'genericPage' && _id == '${preview}') || (_type == 'articleGenericPage' && _id == '${preview}')]| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[(_type == 'genericPage' && slug == ${sanitizeSlug(
            slug,
          )}) || (_type == 'articleGenericPage' && slug == ${sanitizeSlug(
            slug,
          )})]| order(_updatedAt desc)[0] ${groq}`,
        );

    if (get(response, '_type') === 'genericPage') {
      return sanitizeGenericPage(response);
    } else if (get(response, '_type') === 'articleGenericPage') {
      return sanitizeArticleGenericPage(response);
    }
    return null;
  },
  async fetchGlobalSettings() {
    const response =
      await Sanity.fetch(`*[_type == 'globalSettings' && _id == '${GlobalSettingsId}']| order(_updatedAt desc)[0] {
      ...,
      'errorPageImage': errorPageImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'footer': {
        ...,
        'footerImage': {
          'image': footerImage${ImageGroq},
          'alternateImage': footerAlternateImage${ImageGroq},
          'text': footerImageText
        }
      },
      'sideNavigation': sideNavigation[] {
        ...,
        'trendingArticles': trendingArticles[] -> {
          'heroImage': heroImage${ImageGroq},
          heroColor,
          frozen,
          slug,
          title,
          'type': _type,
          articleType,
          language,
          'category': categories[0]->{ slug },
        },
      },
      'researchModuleImage': researchModuleImage${ImageGroq},
      verifiedByExpertsPopUpHeadingImage${ImageGroq},
    }`);

    return sanitizeGlobalSettings(response);
  },
  async fetchAuthorPage(slug, preview = '') {
    const groq = `{
      ...,
      'image': image${ImageGroq},
      'articles': *[_type=='article' && language=='en' && references(^._id)] | order(_updatedAt desc) ${ArticleLinkGroq}[0..9],
      'educationAndTraining': educationAndTraining[] {..., 'logo': logo${ImageGroq}},
      'practiceNames': practiceNames[] {..., 'logo': logo${ImageGroq}}
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'author' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[_type == 'author' && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeAuthorPage(response);
  },
  async fetchShowcasePage(slug, preview = '') {
    const groq = `{
      ...,
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'heroModuleRepeater': heroModuleRepeater[] ${ModuleGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'showcasePage' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[_type == 'showcasePage' && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeShowcasePage(response);
  },
  async fetchAllPages() {
    const response = (await Sanity.fetch(sitemapDocs)) as unknown[];

    return sanitizeAllPages(response);
  },
  async fetchBrowsePage(slug, articleType, preview) {
    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      ...
    }`;

    const query = `*[_type == 'browsePage' && type == '${articleType}' && slug == ${sanitizeSlug(
      slug,
    )}]| order(_updatedAt desc)[0] ${groq}`;

    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'browsePage' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(query);

    const taxonomyKey = articleType === 'Sx' ? 'sxTaxonomy' : 'dxTaxonomy';
    const id = get(response, '_id');
    const taxonomy = get(response, taxonomyKey);

    if (id) {
      const articlesInTaxonomy = (await Sanity.fetch(
        `*[_type == 'article' && "${taxonomy}" in ${taxonomyKey}] | order(modifiedDate desc) ${ArticleLinkGroq}`,
      )) as unknown[];
      const drugArticlesInTaxonomy = (await Sanity.fetch(
        `*[_type == 'drug' && "${taxonomy}" in taxonomy] | order(name) ${DrugLinkGroq}`,
      )) as unknown[];
      return sanitizeBrowsePage(response, [
        ...articlesInTaxonomy,
        ...drugArticlesInTaxonomy,
      ]);
    }

    return null;
  },
  async fetchBrowsePageLinks(taxonomy, articleType, preview) {
    const groq = `{
      _id,
      slug,
      title
    }`;

    const taxonomyKey = articleType === 'Sx' ? 'sxTaxonomy' : 'dxTaxonomy';
    const query = `_type == "browsePage" && type == "${articleType}" && ${taxonomyKey} in [${taxonomy.map(
      (t) => `"${t}"`,
    )}]`;

    const response = preview
      ? await PreviewClient.fetch(`*[${query} && _id == '${preview}'] ${groq}`)
      : await Sanity.fetch(`*[${query}] ${groq}`);

    if ((response as [])?.length) {
      return sanitizeBrowsePageLinks(response, articleType);
    }

    return [];
  },
  async fetchDxArticles() {
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Dx}'] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];

    return response.map(sanitizeArticleLink);
  },
  async fetchSxArticles() {
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Sx}'] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];

    return response.map(sanitizeArticleLink);
  },
  async fetchOtherArticles() {
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Other}'] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];

    return response.map(sanitizeArticleLink);
  },
  async fetchCostArticles() {
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Cost}'] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];

    return response.map(sanitizeArticleLink);
  },
  async fetchQuiz(slug) {
    const query = `
    *[_type == "quiz" && quizType in ['${QuizType.Dx}', '${QuizType.Sx}'] && slug["current"] == '${slug}'][0] {
      'author': author->{ image${ImageGroq}, ... },
      'reviewer': reviewer->{ image${ImageGroq}, ... },
      ...
    }
    `;
    const response = await Sanity.fetch(query);
    const validatedResponse = validateSchema({
      schema: QuizResponseSchema,
      data: response,
    });

    const sanitizedQuiz = sanitizeQuiz(validatedResponse);
    return sanitizedQuiz;
  },

  async fetchQuizzes(type) {
    const query = `
    *[_type == "quiz" && quizType == '${type}'  && !(_id in path("drafts.**"))] {
      'author': author->{ image${ImageGroq}, ... },
      'reviewer': reviewer->{ image${ImageGroq}, ... },
      ...
    }
    `;
    const response = await Sanity.fetch(query);

    const validatedResponse = validateSchema({
      schema: QuizResponseSchema.array(),
      data: response,
    });

    const result: Quiz[] = validatedResponse.map(sanitizeQuiz);

    return result;
  },
};

export default ApiClient;
