import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { FourColumnVideoModule, FourColumnVideoModuleItem } from 'types';

export default memoize((module: unknown): FourColumnVideoModule => {
  return {
    type: get(module, '_type', 'fourColumnVideo'),
    id: get(module, '_key', ''),
    moduleOptions: sanitizeModuleOptions(module),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    contents: get(module, 'contents', []).map(
      (content: unknown): FourColumnVideoModuleItem => ({
        id: get(content, '_key', ''),
        vimeoId: get(content, 'vimeoId', ''),
        title: get(content, 'title', ''),
        image: sanitizeImage(get(content, 'image', {})),
        description: get(content, 'description', ''),
        customPopUpTitle: get(content, 'customPopUpTitle', ''),
        buttonLabel: get(content, 'buttonLabel', 'Watch now'),
      })
    ),
  };
});
