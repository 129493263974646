import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { ContentAnchor } from 'types';

export default memoize((module: unknown): ContentAnchor => {
  return {
    label: get(module, 'sideNavLabel', ''),
    anchor: get(module, 'sideNavSlug', ''),
    id: get(module, '_key', ''),
  };
});
