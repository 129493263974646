import React, { FC } from 'react';
import Head from 'next/head';
import type { ProviderPage } from 'types';

type Props = {
  providerPage: ProviderPage;
};

const ProviderPageStructuredDataSchema: FC<Props> = ({ providerPage }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'MedicalWebPage',
            audience: {
              '@type': 'Audience',
              name: 'https://schema.org/Patient',
            },
            headline: providerPage.seo.title,
            description: providerPage.seo.description,
          }),
        }}
      />
    </Head>
  );
};

export default ProviderPageStructuredDataSchema;
