import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeSideNavOption from 'state/sanitizers/articleBodyModules/sanitizeSideNavOption';

import { ArticleButton } from 'types';

export default memoize((module: unknown): ArticleButton => {
  return {
    type: get(module, '_type', 'articleButton'),
    id: get(module, '_key', ''),
    sideNav: sanitizeSideNavOption(module),
    label: get(module, 'label', ''),
    link: sanitizeInternalLink(get(module, 'link', '')),
    logoIsActive: get(module, 'displayWithLogo', false),
  };
});
