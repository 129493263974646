import { useRouter } from 'next/router';
import { RouteMap } from 'constants/Routes';
import usePage from 'hooks/usePage';

function useBlogBase(): { blogBase: string; isFrozen: boolean } {
  const page = usePage();
  const router = useRouter();
  const hasCategory = router.query.category || router.query.categoryOrSlug;
  const hasSlug = router.query.slug;
  const hasBlogInPath = router.asPath.includes(RouteMap.BLOG.base);

  const isBlogHomePage = router.asPath === RouteMap.BLOG.base;
  const isCategoryPage = hasCategory && !hasSlug;
  const isBlogPage = hasCategory && hasSlug;
  const isSubCategoryPage =
    (hasBlogInPath && (router.query.slug ?? []).length >= 1) ||
    (!hasBlogInPath && (router.query.slug ?? []).length > 1);

  if (
    (isCategoryPage || isSubCategoryPage || isBlogHomePage) &&
    hasBlogInPath
  ) {
    return {
      blogBase: RouteMap.BLOG.base,
      isFrozen: true,
    };
  }

  if (isBlogPage) {
    const isFrozen = page?.blogPost?.frozen || false;
    return {
      blogBase: isFrozen ? RouteMap.BLOG.base : '',
      isFrozen: isFrozen,
    };
  }
  return {
    blogBase: '',
    isFrozen: false,
  };
}

export default useBlogBase;
