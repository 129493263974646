import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { GeneralInquiryModule } from 'types';

export default memoize((module: unknown): GeneralInquiryModule => {
  return {
    id: get(module, '_key', ''),
    type: get(module, '_type', 'generalInquiry'),
    formSubmissionMethod: get(module, 'submissionMethod', 'chili-piper'),
    formLeadSource: get(module, 'formLeadSource', ''),
    formLeadSourceOtherC: get(module, 'formLeadSourceOtherC', ''),
  };
});
