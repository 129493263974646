import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeLottie from 'state/sanitizers/sanitizeLottie';

import { AnimationAndTextModule } from 'types';

export default memoize((module: unknown): AnimationAndTextModule => {
  return {
    type: get(module, '_type', 'animationAndTextModule'),
    id: get(module, '_key', ''),
    header: get(module, 'header', ''),
    description: get(module, 'description', []),
    button: {
      label: get(module, 'symptomCheckerButtonLabel', ''),
      url: get(module, 'symptomCheckerButtonUrl', ''),
    },
    image: sanitizeImage(get(module, 'image')),
    lottie: sanitizeLottie(get(module, 'lottie')),
  };
});
