import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeArticleLinks from 'state/sanitizers/sanitizeArticleLinks';

import { AuthorPage } from 'types';

export default memoize((authorPage: unknown): AuthorPage | null => {
  const id = get(authorPage, '_id');

  if (!id) {
    return null;
  }

  const honorifics = get(authorPage, 'honorifics', '');
  const firstName = get(authorPage, 'firstName', '');
  const lastName = get(authorPage, 'lastName', '');

  return {
    id: get(authorPage, '_id', ''),
    firstName,
    lastName,
    honorifics,
    fullName: honorifics
      ? `${firstName} ${lastName}, ${honorifics}`
      : `${firstName} ${lastName}`,
    specialty: get(authorPage, 'specialty', ''),
    slug: get(authorPage, 'slug', ''),
    image: sanitizeImage(get(authorPage, 'image')),
    bio: get(authorPage, 'bio', []),
    links: get(authorPage, 'links', []).map((link: unknown) => {
      return {
        id: get(link, '_key', ''),
        label: get(link, 'label', ''),
        url: get(link, 'url', ''),
      };
    }),
    educationAndTraining: get(authorPage, 'educationAndTraining', []).map(
      (educationInfo: unknown) => {
        return {
          id: get(educationInfo, '_key', ''),
          details: get(educationInfo, 'details', ''),
          logo: sanitizeImage(get(educationInfo, 'logo', '')),
        };
      }
    ),
    practiceNames: get(authorPage, 'practiceNames', []).map(
      (practiceName: unknown) => {
        return {
          id: get(practiceName, '_key', ''),
          name: get(practiceName, 'name', ''),
          logo: sanitizeImage(get(practiceName, 'logo', '')),
          link: {
            label: get(practiceName, 'linkLabel', ''),
            url: get(practiceName, 'linkUrl', ''),
          },
        };
      }
    ),
    certifications: get(authorPage, 'certifications', []),
    awards: get(authorPage, 'awards', []),
    publications: get(authorPage, 'publications', []),
    articles: sanitizeArticleLinks(get(authorPage, 'articles', [])),
  };
});
