import memoize from 'lodash/memoize';
import get from 'lodash/get';

import { InstagramFeedModule } from 'types';

export default memoize((module: unknown): InstagramFeedModule => {
  return {
    type: get(module, '_type', 'instagramFeed'),
    id: get(module, '_key', ''),
    title: get(module, 'title', ''),
    buttonLabel: get(module, 'buttonLabel', ''),
    buttonUrl: get(module, 'buttonUrl', ''),
  };
});
