import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeArticleBody from 'state/sanitizers/sanitizeArticleBody';

import { ArticleGenericPage } from 'types';

export default memoize(
  (articleGenericPage: unknown): ArticleGenericPage | null => {
    const id = get(articleGenericPage, '_id');

    if (!id) {
      return null;
    }

    return {
      id,
      type: get(articleGenericPage, '_type', 'articleGenericPage'),
      slug: get(articleGenericPage, 'slug', ''),
      title: get(articleGenericPage, 'title', ''),
      showIntercom: get(articleGenericPage, 'showIntercom', false),
      bodyModules: sanitizeArticleBody(
        get(articleGenericPage, 'articleBodyModuleRepeater', []),
      ),
      date:
        get(articleGenericPage, 'date', '').replace(/-/g, '/') ||
        get(articleGenericPage, '_updatedAt') ||
        get(articleGenericPage, '_createdAt') ||
        '',
      disableAds: get(articleGenericPage, 'disableAds', false),
    };
  },
);
