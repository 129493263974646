import React, { FC, ReactNode } from 'react';

import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

import { ContentAnchor } from 'types';

interface Props {
  section: ContentAnchor;
  children: ReactNode;
  offset?: number;
  duration?: number;
  disableAds?: boolean;
}

const ScrollableAnchorWrapper: FC<Props> = ({
  section,
  children,
  offset = -200,
  duration = 200,
  disableAds = false,
}) => {
  configureAnchors({ offset, scrollDuration: duration });

  if (!section.label && !section.anchor) {
    return <>{children}</>;
  }

  /*
   * The id passed to <ScrollableAnchor /> is not visible to the DOM
   * so it won't conflict with this id used for document.querySelector
   */
  const contentId = `${section.id}-${section.anchor}`;

  return (
    <ScrollableAnchor id={section.anchor}>
      <div id={contentId} className={disableAds ? 'disable-ads' : ''}>
        {children}
      </div>
    </ScrollableAnchor>
  );
};

export default ScrollableAnchorWrapper;
