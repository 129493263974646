import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import Head from 'next/head';

import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import usePrevious from 'hooks/usePrevious';
import useScrollDirection, { ScrollDirection } from 'hooks/useScrollDirection';

import { Img } from 'styled';
import {
  PageHero as StyledPageHero,
  FullImgContainer,
  PageHeroButton,
} from './style';
import ModuleSwitch from 'components/ModuleSwitch';

import useLanguage from 'hooks/useLanguage';
import { EmphasizeModuleColorScheme } from 'constants/ColorScheme';
import { RouteMap, RoutesWithoutPageHeroButton } from 'constants/Routes';

import { PageHero as IPageHero, GlobalState, Image } from 'types';

interface PassedProps {
  hero: IPageHero;
}

interface StoreProps {
  notificationBarIsActive: boolean | null;
  notificationBarHeight: number;
}

type Props = PassedProps & StoreProps & WithBreakpointsProps & WithRouterProps;

interface State {
  buttonContainerOffsetTop: number;
  // Ensures that SSR and CSR will render same image and rehydrate properly
  image: Image | null;
}

function PageHero(props: Props) {
  const {
    hero,
    notificationBarIsActive,
    notificationBarHeight,
    router,
    currentBreakpoint,
  } = props;

  const Language = useLanguage();
  const prevProps = usePrevious(props);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const scrollDirection = useScrollDirection({
    initialDirection: ScrollDirection.SCROLL_UP,
  });
  const [state, setState] = useState<State>({
    buttonContainerOffsetTop: 0,
    image: null,
  });

  const setHeroImage = useCallback(() => {
    const { currentBreakpoint, hero } = props;
    const { mobileImage, desktopImage } = hero;
    const breakpointIsSmDown = ['EXTRA_SMALL', 'SMALL'].includes(
      currentBreakpoint,
    );

    const newImage =
      breakpointIsSmDown && mobileImage.id ? mobileImage : desktopImage;

    setState((s) => ({
      ...s,
      image: newImage,
    }));
  }, [props]);

  const setButtonContainerOffsetTop = useCallback(() => {
    if (buttonContainerRef && buttonContainerRef.current) {
      const offsetTop = buttonContainerRef.current.offsetTop;

      setState((s) => ({
        ...s,
        buttonContainerOffsetTop: offsetTop,
      }));
    }
  }, []);

  useEffect(() => {
    setHeroImage();
    setButtonContainerOffsetTop();
  }, [setButtonContainerOffsetTop, setHeroImage]);

  useEffect(() => {
    /*
     * If hero image needs to be updated to the right version based on viewport,
     * we update state to ensure rendering the current image
     */
    if (
      prevProps?.currentBreakpoint !== props.currentBreakpoint &&
      // Mobile image is optional in Sanity
      !!props.hero.mobileImage.id &&
      props.hero.mobileImage.id !== props.hero.desktopImage.id
    ) {
      const breakpointWasSmDown = ['EXTRA_SMALL', 'SMALL'].includes(
        prevProps?.currentBreakpoint || '',
      );

      const breakpointIsSmDown = ['EXTRA_SMALL', 'SMALL'].includes(
        props.currentBreakpoint,
      );

      if (
        (!breakpointWasSmDown && breakpointIsSmDown) ||
        (breakpointWasSmDown && !breakpointIsSmDown)
      ) {
        setHeroImage();
      }
    }

    // If hero image from props changes, we update state to ensure rendering the current image
    if (
      prevProps?.hero.mobileImage.id !== props.hero.mobileImage.id ||
      prevProps?.hero.desktopImage.id !== props.hero.desktopImage.id
    ) {
      setHeroImage();
    }
  }, [props, prevProps, setHeroImage]);

  const { buttonContainerOffsetTop, image } = state;

  const {
    variant,
    buttonLink,
    buttonLabel,
    buttonVariant,
    gtmTrackerEventName,
  } = hero;
  // per SR-1364: when all three Hero Button fields are empty, do not render
  const pageHeroButtonUndefined = !buttonLink && !buttonLabel && !buttonVariant;
  const colorScheme = EmphasizeModuleColorScheme[hero.color];
  const variantIsLg = variant.includes('lg');
  const variantIsMd = variant.includes('md');
  const variantIsSm = variant.includes('sm');
  const selectedButtonLabel =
    buttonLabel || Language.t('SymptomChecker.chatAboutSymptoms');
  const selectedButtonLink = buttonLink || RouteMap.SYMPTOM_CHECKER.path;
  const selectedButtonAriaLabel = buttonLabel
    ? Language.t('Global.pageButtonAriaLabel', { title: buttonLabel })
    : Language.t('SymptomChecker.ariaLabel');

  const selectedButtonVariant = buttonVariant
    ? buttonVariant.includes('inline-')
      ? buttonVariant.replace('inline-', '')
      : buttonVariant
    : !!buttonLink && !!buttonLabel
      ? 'hero'
      : 'symptom-checker-white';

  const hidePageHeroButton =
    RoutesWithoutPageHeroButton.some((basePath) =>
      router.asPath.includes(basePath),
    ) && !router.asPath.includes(RouteMap.CONTENT_HUB_HEALTH_A_TO_Z.path);

  const isContentHubLanding = router.asPath.includes(
    RouteMap.CONTENT_HUB_LANDING.path,
  );

  const isHeroInline = buttonVariant && buttonVariant.includes('inline-');

  const imageSrc = image ? sanityImgUtil(image, 768) : undefined;
  const imageSrcSet = image
    ? sanityImgSrcSetUtil(image, 768, 1024, 1990)
    : undefined;

  // To-do: Refactor to use styled components entirely

  return (
    <>
      {/* Preload responsive page hero image */}
      <Head>
        {image && !!imageSrc && (
          <link
            rel="preload"
            href={imageSrc}
            // @ts-ignore
            imagesrcset={imageSrcSet}
            // @ts-ignore
            imagesizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
            as="image"
          />
        )}
      </Head>
      <StyledPageHero
        className={cx('PageHero', {
          'py2_5 sm:pt3 sm:pb1_5': variant === 'content-hub-md',
        })}
        mobileImageWidth={hero.mobileImageWidth}
        desktopImageWidth={hero.desktopImageWidth}
        aspectRatio={(image && image.metadata?.dimensions.aspectRatio) || 1}
        data-testid="page-hero"
        style={{ backgroundColor: colorScheme.background }}
      >
        {variantIsLg && image && imageSrc && (
          <div className="t0 r0 l0 w100 h100 absolute z-0 overflow-x-hidden overflow-y-hidden">
            <FullImgContainer
              position="absolute"
              top={0}
              left={0}
              right={0}
              mx="auto"
              mobileImageWidth={hero.mobileImageWidth}
              desktopImageWidth={hero.desktopImageWidth}
            >
              <Img
                useLazyLoadComponent={false}
                alt={image.alt}
                sizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
                src={imageSrc}
                srcSet={imageSrcSet}
                preloadContainerClassName="w100"
                dimensions={image.metadata?.dimensions}
                crop={image.crop}
              />
            </FullImgContainer>
          </div>
        )}
        <div
          className={cx(`PageHero__container PageHero__container--${variant}`, {
            'md:px2_25': variantIsLg,
            px1_5: variant !== 'general-md',
          })}
          style={{
            color: colorScheme.text,
          }}
        >
          {!isHeroInline &&
            !hidePageHeroButton &&
            !pageHeroButtonUndefined &&
            (variant === 'general-md' || variant === 'general-sm') && (
              <div
                style={{
                  height: `calc(100% - ${buttonContainerOffsetTop}px + 4.5rem)`,
                }}
                className="PageHero__button h100 w100 events-none l0 r0 z-hero-button none absolute flex-col items-center md:flex"
              >
                <PageHeroButton
                  notificationBarIsActive={notificationBarIsActive}
                  notificationBarHeight={notificationBarHeight}
                  ariaLabel={selectedButtonAriaLabel}
                  containerClassName="sm:sticky z-hero-button t0"
                  className="events-all inline-flex"
                  label={selectedButtonLabel}
                  to={selectedButtonLink}
                  variant={selectedButtonVariant as any}
                  gtmTrackerEventName={gtmTrackerEventName}
                />
              </div>
            )}
          <div
            className={cx(`PageHero__inner-container--${variant}`, {
              'flex flex-col sm:flex-row': variantIsMd,
              'items-start': variant === 'general-md',
              'items-center sm:items-start': variant === 'content-hub-md',
            })}
          >
            <div
              className={cx(
                `PageHero__text-container--${variant} flex flex-col`,
                {
                  'items-center text-center': variant !== 'general-md',
                  'col-12 sm:col-6 px1_5 md:pl5_25 md:pr1_5':
                    variant === 'general-md',
                  'w100 sm:mr1 flex-1 sm:items-start':
                    variant === 'content-hub-md',
                },
              )}
            >
              {hero.description && variant === 'content-hub-sm' && (
                <p className="PageHero__description z-2 mxauto mb_5 relative text-center text-sm">
                  {hero.description}
                </p>
                /* Inline CTA goes here */
              )}
              <h1
                className={cx(`PageHero__title--${variant} z-2 relative`, {
                  'text-xl-xxl md-up-text-xxxl':
                    variantIsLg && !isContentHubLanding,
                  'text-md-lg sm-up_text-xl-xxl':
                    (variantIsLg && isContentHubLanding) ||
                    variant === 'content-hub-md',
                  'md-up_text-xxl text-xl':
                    variant === 'general-md' || variantIsSm,
                })}
              >
                {hero.title}
              </h1>
              {hero.description && variant !== 'content-hub-sm' && (
                <>
                  <p
                    className={cx('PageHero__description z-2 relative', {
                      'text-sm': variant !== 'content-hub-md',
                      mt1_5:
                        (variantIsLg && !isContentHubLanding) || variantIsSm,
                      mt1: variantIsLg && isContentHubLanding,
                      'md-up_text-md mt1_5 md:mt2_5': variant === 'general-md',
                      'text-md sm-up_text-md-lg mt1':
                        variant === 'content-hub-md',
                    })}
                  >
                    {hero.description}
                  </p>
                  {isHeroInline && (
                    <PageHeroButton
                      ariaLabel={selectedButtonAriaLabel}
                      className="events-all inline-flex"
                      containerClassName="sm:sticky inline-flex z-hero-button t0 mt1_5 md:mt2_5"
                      label={selectedButtonLabel}
                      notificationBarHeight={notificationBarHeight}
                      notificationBarIsActive={notificationBarIsActive}
                      to={selectedButtonLink}
                      variant={selectedButtonVariant as any}
                      gtmTrackerEventName={gtmTrackerEventName}
                    />
                  )}
                </>
              )}
              {!isHeroInline && variant === 'content-hub-md' && (
                <div className="PageHero__button-container--content-hub-md border-top-gray-lighter w100 pt1 my1 sm:mb0 flex flex-col sm:items-start">
                  <p className="PageHero__button-container-text--content-hub-md">
                    {Language.t('SymptomChecker.notSureWhatYouAreLookingFor')}
                  </p>
                  <PageHeroButton
                    notificationBarIsActive={notificationBarIsActive}
                    notificationBarHeight={notificationBarHeight}
                    ariaLabel={selectedButtonAriaLabel}
                    containerClassName="flex justify-center mt1"
                    label={selectedButtonLabel}
                    to={selectedButtonLink}
                    variant={selectedButtonVariant as any}
                    gtmTrackerEventName={gtmTrackerEventName}
                  />
                </div>
              )}
            </div>
            {variant === 'general-md' && image && !!imageSrc && (
              <Img
                useLazyLoadComponent={false}
                alt={image.alt}
                sizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
                src={imageSrc}
                srcSet={imageSrcSet}
                preloadContainerClassName="col-12 sm:col-6"
                className="fit-contain"
                dimensions={image.metadata?.dimensions}
                crop={image.crop}
              />
            )}
            {variant === 'content-hub-md' && image && !!imageSrc && (
              <Img
                useLazyLoadComponent={false}
                alt={image.alt}
                src={sanityImgUtil(image, 486)}
                preloadContainerClassName="PageHero__img--content-hub-md w100"
                className="fit-contain"
                dimensions={image.metadata?.dimensions}
                crop={image.crop}
              />
            )}
          </div>
          {!isHeroInline && !hidePageHeroButton && variant === 'general-lg' && (
            <>
              <div
                ref={buttonContainerRef}
                style={{
                  height: `calc(100% - ${buttonContainerOffsetTop}px + 4.5rem)`,
                }}
                className="PageHero__button-container--general-lg h100 w100 events-none l0 r0 absolute flex flex-col items-center"
              >
                <PageHeroButton
                  topNavIsVisible={
                    ['LARGE', 'EXTRA_LARGE', 'EXTRA_EXTRA_LARGE'].includes(
                      currentBreakpoint,
                    ) && RouteMap.CONTENT_HUB_LANDING.path === router.pathname
                      ? scrollDirection === ScrollDirection.SCROLL_UP
                      : true
                  }
                  notificationBarIsActive={notificationBarIsActive}
                  notificationBarHeight={notificationBarHeight}
                  ariaLabel={selectedButtonAriaLabel}
                  containerClassName={`flex justify-center md:sticky z-hero-button ${
                    isContentHubLanding ? 'mt1_5' : 'mt2_25'
                  }`}
                  className="events-all inline-flex"
                  label={selectedButtonLabel}
                  to={selectedButtonLink}
                  variant={selectedButtonVariant as any}
                  gtmTrackerEventName={gtmTrackerEventName}
                />
              </div>
              <div className="PageHero__button-space" />
            </>
          )}
          <div className="PageHero__module-container z-2 relative overflow-x-hidden">
            <ModuleSwitch
              modules={hero.modules}
              hasWrapper={true}
              color={hero.color}
              textColor={colorScheme.text}
            />
          </div>
        </div>
      </StyledPageHero>
    </>
  );
}

const mapStateToProps = (state: GlobalState): StoreProps => ({
  notificationBarHeight: state.applicationUI.notificationBarHeight,
  notificationBarIsActive: state.applicationUI.notificationBarIsActive,
});

export default withBreakpoints(withRouter(connect(mapStateToProps)(PageHero)));
